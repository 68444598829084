import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AlarmRouteModel, CommonServiceTime, DeliveryType, Route, ServiceTimeValidity } from '../../models/alarmroutes.model';
import { CallCenter } from '../../models/callcenter.model';
import { ActivatedRoute } from '@angular/router';
import { Location, CommonModule } from '@angular/common';
import { LocationModel } from '../../models/location.model';
import { Sort, MatSortModule } from '@angular/material/sort';
import { orderBy } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { AlarmRouteInfoComponent } from './alarm-route-info/alarm-route-info.component';
import { AlarmRouteEditComponent } from './alarm-route-edit/alarm-route-edit.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-alarm-route',
  templateUrl: './alarm-route.component.html',
  styleUrls: ['./alarm-route.component.css'],
  standalone: true,
  imports: [CommonModule, MatSortModule, MatTooltipModule, AlarmRouteEditComponent, AlarmRouteInfoComponent, TranslateModule]
})
export class AlarmRouteComponent implements OnInit {

  constructor(private api: ApiService, private angularRoute: ActivatedRoute, private location: Location) { }

  user: any;
  alarmRouteLocations: AlarmRouteLocation[];
  selectedRoute: Route;
  callCenters: CallCenter[];
  showInformationForm: boolean;
  showEditForm: boolean;
  newRoute: boolean;
  showDefaultRoutes: boolean = false;

  activeTabIndex: number = 0;

  setActiveTabIndex(value: number): void {
    this.activeTabIndex = value;
  }

  deliveryTypeIconMap: Map<DeliveryType, string> = new Map([
    [DeliveryType.CallSMS, 'fal fa-phone'],
    [DeliveryType.CallGroupSMS, 'fal fa-users'],
    [DeliveryType.Email, 'fal fa-envelope'],
    [DeliveryType.Log, 'fal fa-file'],
    [DeliveryType.Smsonly, 'fal fa-comment']
  ]);

  // Creates a new template when creating new alarm route
  createNewRoute(): void {
    this.selectedRoute = {
      callCenterFirst: false,
      emaBeforeCallCenter: false,
      keepAlarmOpenAfterCallCenter: false,
      alarmIndicationGroupEnabled: false,
      waitForSmsAcknowledgement: true,
      alarmIndicationGroups: [],
      callCenterId: '',
      defaultRoute: false,
      deliveryType: DeliveryType.CallSMS,
      devices: [],
      enabled: true,
      fallBackCallCenter: 'NONE',
      fallBackRecipients: [],
      fallBackType: DeliveryType.None,
      callLoopFailType: 'NONE',
      id: '',

      skipInactiveTeamsForAssistance: false,
      // If user belongs to location, then use customerId as default location id, if not then use user locationId.
      locationId: this.user.locationId.startsWith('C0') ? this.user.customerId : this.user.locationId,
      locationName: '',
      name: '',
      priority: 4,
      recipients: [],
      serviceTimeValidity: ServiceTimeValidity.Always,
      serviceTimes: [
        {
          'day': 'mon',
          'startTime': '00:00:00',
          'endTime': '00:00:00'
        },
        {
          'day': 'tue',
          'startTime': '00:00:00',
          'endTime': '00:00:00'
        },
        {
          'day': 'wed',
          'startTime': '00:00:00',
          'endTime': '00:00:00'
        },
        {
          'day': 'thu',
          'startTime': '00:00:00',
          'endTime': '00:00:00'
        },
        {
          'day': 'fri',
          'startTime': '00:00:00',
          'endTime': '00:00:00'
        },
        {
          'day': 'sat',
          'startTime': '00:00:00',
          'endTime': '00:00:00'
        },
        {
          'day': 'sun',
          'startTime': '00:00:00',
          'endTime': '00:00:00'
        }
      ],

      comment: ''
    };

    this.newRoute = true;
    this.showEditForm = true;
  }

  async getAlarmRoutes(): Promise<AlarmRouteModel[]> {
    return new Promise<AlarmRouteModel[]>(resolve => this.api.getAlarmRoutes().subscribe(res => {
      resolve(res)
    }));
  }

  async getLocations(): Promise<LocationModel[]> {
    return new Promise<LocationModel[]>(resolve => this.api.getLocations().subscribe(res => {
      resolve(res)
    }));
  }

  async getCustomerInformation(): Promise<any> {
    return new Promise<any>(resolve => {
      this.api.getCustomerInformation().subscribe(res => {
        resolve(res);
      });
    });
  }

  async createEmptyCustomerLocation(): Promise<AlarmRouteLocation> {
    const customerInformation = await this.getCustomerInformation();
    return {
      locationName: customerInformation.name,
      routes: [],
      locationId: customerInformation.customerId,
      collapsed: true
    };
  }

  async getAlarmRouteLocations(): Promise<void> {
    const tempLocations: LocationModel[] = await this.getLocations() || [];
    const tempAlarmRoutesLocations: AlarmRouteModel[] = await this.getAlarmRoutes();

    this.alarmRouteLocations = tempLocations.map(location => {

      // If we don't have any alarmRouteLocations then return alarmRouteLocation with empty routes
      if (!tempAlarmRoutesLocations) {
        return { locationName: location.name, routes: [], locationId: location.id, collapsed: true }
      }

      const tempAlarmRouteLocation = tempAlarmRoutesLocations.find(alarmRouteLocation => alarmRouteLocation.locationId === location.id);

      // If no location id matches AlarmRoute location id, return alarmRouteLocation with empty routes
      if (!tempAlarmRouteLocation) {
        return { locationName: location.name, routes: [], locationId: location.id, collapsed: true }
      }

      // Add found alarmRoutes to this location
      return { locationName: location.name, routes: tempAlarmRouteLocation.routes, locationId: location.id, collapsed: true }
    });


    // If user belongs to customer location, then handle customer location accordingly
    if (this.user.locationId.startsWith('C0')) {
      let customerLocation;

      // Check if customer location exists, if not then creat empty one
      if (tempAlarmRoutesLocations) {
        customerLocation = tempAlarmRoutesLocations.find(route => route.locationId.startsWith('C0'))
      }

      if (!customerLocation) {
        customerLocation = await this.createEmptyCustomerLocation();
      }

      // Move customer location to top of the list.
      if (customerLocation) {
        this.alarmRouteLocations.unshift({
          locationName: customerLocation.locationName,
          routes: customerLocation.routes,
          locationId: customerLocation.locationId,
          collapsed: true
        });
      }
    }
  }

  getCallCenters(): void {
    this.api.getCallCenters().subscribe(res => this.callCenters = res);
  }

  sortRoutes() {
    this.alarmRouteLocations.forEach((alarmRoutes => {
      alarmRoutes.routes.sort((n1, n2) => n1.priority - n2.priority)
    }));
  }

  showRouteInformation(routeId: string): void {
    this.getRouteDetails(routeId);
    this.showInformationForm = true;
    this.location.go('/route/' + routeId);
  }

  getRouteDetails(routeId: string): void {
    this.selectedRoute = null;
    this.api.getAlarmRouteDetails(routeId).subscribe(res => {
      this.selectedRoute = res;
    });
  }

  trimTime(time: string): string {
    return time.substring(0, Math.max(time.length - 3, 5));
  }

  parseServiceTime(serviceTimeValidity: ServiceTimeValidity, commonServiceTime: CommonServiceTime) {
    switch (serviceTimeValidity) {
      case ServiceTimeValidity.Never: {
        return 'NEVER';
      }
      case ServiceTimeValidity.Always: {
        return 'ALWAYS';
      }
      case ServiceTimeValidity.Daily: {
        if (commonServiceTime) {
          return `${this.trimTime(commonServiceTime.startTime)} - ${this.trimTime(commonServiceTime.endTime)}`;
        } else {
          return 'DAILY';
        }
      }
    }
  }

  showCustomerLocation() {
    return this.user.locationId.substring(0, 2) === 'C0';
  }

  isCustomerLocation(location: AlarmRouteLocation) {
    return location.locationId.substring(0, 2) === 'C0';
  }

  toggleCollapseState(location: AlarmRouteLocation) {
    location.collapsed = !location.collapsed;
  }

  routeDeleted(routeId: string): void {
    this.alarmRouteLocations.forEach((alarmRouteLocation) => {
      const deleteRouteIndex: number = alarmRouteLocation.routes.findIndex(route => route.id === routeId);

      if (deleteRouteIndex !== -1) {
        alarmRouteLocation.routes.splice(deleteRouteIndex, 1);
        return;
      }
    });
  }

  routeCreated(): void {
    this.getAlarmRouteLocations().then(() => {
      this.sortRoutes();
    });
  }

  routeEdited(editedRoute: Route): void {
    this.selectedRoute = editedRoute;
    this.getAlarmRouteLocations().then(() => {
      this.sortRoutes();
    })
  }

  closeInformationForm(): void {
    this.showInformationForm = false;
    this.location.go('route');
  }

  sortData(sort: Sort, index: number) {
    if (!sort.active || sort.direction == '') {
      return;
    }

    this.alarmRouteLocations[index].routes = orderBy(
      this.alarmRouteLocations[index].routes,
      [sort.active, sort.active === 'serviceTimeValidity' ? 'commonServiceTime.endTime' : ''],
      sort.direction
    );
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getCallCenters();

    this.getAlarmRouteLocations().then(() => {
      this.sortRoutes();

      //Check if user comes with URL containing the route ID
      let routeId;
      //Get ID from the URL
      this.angularRoute.params.subscribe(params => {
        routeId = params['id'];
      });

      //If ID was found from the URL, check if route actually exists, retrieve route information and show information form.
      if (routeId) {

        const routeExists: boolean = this.alarmRouteLocations.some(location => {
          return location.routes.some(route => route.id === routeId);
        });

        if (routeExists) {
          this.showRouteInformation(routeId);
        }
      }
    })
  }
}

class AlarmRouteLocation implements AlarmRouteModel {
  locationId: string;
  locationName: string;
  routes: Route[];
  collapsed?: boolean;
}


