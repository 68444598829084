import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '../../environments/environment';
import * as moment from "moment";
import * as emasync from '../modules/emasync';
import { VideoServerService, VideoServerUser } from './videoserver.service';
import { ICategoryAndReasonTranslated } from 'app/models/alarm-reasons.model';
import { ApiService } from './api.service';

//
// Active alert states (not including completed)
//
export enum CrAlertState {
  Available,
  Reserved,
  Started
}


//
// CrAlert is the representation of an alert, when viewed and processed
// in the control room application. It has read-only projection of the
// original alert parameters (from wire) and some read-write parameters
// for storing alert related data in application.
//
export class CrAlert {
  constructor(
    // read-only projection of alert parameters
    readonly userId: string,
    readonly alertId: string,
    readonly time: moment.Moment,
    readonly header: string,
    readonly productId: string,
    readonly body: string,
    readonly reservedBy: string,
    readonly startedBy: string,
    readonly reRoutedBy: string,
    readonly state: CrAlertState,
    readonly isForwarded: boolean,
    readonly address: string,
    readonly deviceType: string,
    readonly alertGroup: number,
    readonly alertNode: number,
    readonly customerId: string,
    readonly customerName: string,
    readonly baseMISDN: string,
    readonly place: string,
    readonly increment: number,
    // read-write parameters for use in application
    public allowTransition: boolean,
    public transitional: boolean,
    public notes: CrNote[],
    public alarmReasons: ICategoryAndReasonTranslated[],
    public locationName: string,
    public positionLink: string,
    public positionLatitude: number,
    public positionLongitude: number,
    public positionPrecision: number,
    public positionType: string,
  ) {}
};


//
// Different alert update types
//
export enum CrAlertEventType {
  UpdateAllAlerts,
  UpdateOneAlert,
  DeleteOneAlert
};


//
// Alert update is notified to application as CrAlertEvent
// via alerts observables (active and forwarded)
//
export class CrAlertEvent {
  constructor(
    readonly userId: string,
    readonly type: CrAlertEventType,
    readonly alertList: CrAlert[],
    readonly alertId: string
  ) {}
}


//
// Internal state of crsync service
//
export enum CrSyncState {
  Init,
  Connecting,
  Reconnecting,
  Online,
  Offline,
  Error,
  Completed
};


//
// Team member information
//
export class CrTeamMember {
  constructor(
    readonly userId: string,
    readonly name: string,
    readonly initials: string,
    readonly isOnline: boolean,
    // multiple phone numbers are supported for multi-SIM phones
    readonly phoneNumbers: CrPhoneNumber[],
    readonly photoAvatar: string
  ) {}

  public toString = () : string => this.name;
};


//
// Phone number is a pair of display name (in practice, mobile
// operator name) and a phone number
//
export class CrPhoneNumber {
  constructor(
    readonly displayName: string,
    readonly phoneNumber: string
  ) {}
};


//
// Team member event provides up-to-date list of team members
// and their statuses (for the main or alias user indicated by userId).
//
// Team member array is alphabetically sorted by name.
//
export class CrTeamMemberEvent {
  constructor(
    readonly userId: string,
    readonly teamMembers: CrTeamMember[]
  ) {}
};

// Notes added by operator or nurse
export class CrNote {
  constructor(
    public text: string,
    public writtenBy: string,
    public time: any
  ){}
}

//
// CrSyncService serves the following purposes:
// - adapts pure JS/TS emasync module to Angular application as a service
// - converts evolving, loose wire events (from callback) to rigid, strongly
//   typed events (RxJS Observable), and back
// - hides alert server details (e.g. REST API) from control room application
// - manages and multiplexes several user alias connections
//
@Injectable()
export class CrSyncService {
  private http: HttpClient;
  private authData: any;
  private sessions: { [userId: string]: EmaSyncSession } = {};
  private _state: CrSyncState = CrSyncState.Init;
  private _userId: string;
  private _aliases: any[];

  private alertsObservable: Observable<CrAlertEvent>;
  private alertsSubscriber: Subscriber<CrAlertEvent>;
  private teamObservable: Observable<CrTeamMemberEvent>;
  private teamSubscriber: Subscriber<CrTeamMemberEvent>;
  private fwdAlertsObservable: Observable<CrAlertEvent>;
  private fwdAlertsSubscriber: Subscriber<CrAlertEvent>;
  private onlineObservable: Observable<boolean>;
  private onlineSubscriber: Subscriber<boolean>;
  private exitObservable: Observable<void>;
  private exitSubscriber: Subscriber<void>;


  // wire (monitored) alerts contain all alerts as received from alert server
  // (these are transformed to EmaAlert lists for the application)
  private _wireAlerts: { [alertId: string]: WireAlert } = {};
  private _wireMonitoredAlerts: { [alertId: string]: WireAlert } = {};

  private asBaseUrl = environment.alertServerBaseUrl;


  //
  // public methods
  //

  constructor(
    backend: HttpBackend,
    private videoServerService: VideoServerService,
    private api: ApiService,
  ) {
    // use pristine backend, because default one will override
    // authentication headers in an interceptor
    this.http = new HttpClient(backend);
  }


  //
  // Connect to alert server and possibly video controller.
  //
  // When username and password are provided, basic authentication with these credentials are used.
  // Otherwise connection is established with authentication token stored in local storage.
  // Connection is periodically retried until it succeeds.
  //
  // Returns false if username nad password is not given and authtoken is not found, or connection
  // has already been initiated. Otherwise true is returned.
  //
  connect(username: string = "", password: string = "", reconnect: boolean = false): boolean {
    if (!reconnect && this._state !== CrSyncState.Init && this._state !== CrSyncState.Completed) return false;
    let authToken: string  = "";
    if (!username || !password) {
      var userStr = localStorage.getItem("alertServerUser");
      if (userStr) {
        let user: any = null;
        try {
          user = JSON.parse(userStr);
          authToken = user.authToken;
          username = user.username;
          console.log("Connecting to Alert Server with authtoken, username:", username);
        } catch(e) {
        }
      }
      if (!authToken) {
        console.warn("Could not retrieve authtoken for alertserver.")
        return false;
      }
    } else {
      console.log("Connecting to Alert Server using basic authentication");
    }

    // placeholder: credential override for local development
    let origUsername = username;
    let origPassword = password;
    if (false) {
      username = "app_test1";
      password = "123456";
    }

    this._state = reconnect ? CrSyncState.Reconnecting : CrSyncState.Connecting;
    let url = this.asBaseUrl + '/api/login' + (authToken ? "?authtoken=" + authToken : "");
    let data = {};
    let options = authToken ? undefined : {
      headers: { 'Authorization': "Basic " + btoa(`${username}:${password}`) }
    };
    this.http.post(url, data, options).subscribe((res: any) => {
      if (res.userId && res.companyId && res.authToken) {
        console.log("Alert Server login OK.")
        this.authData = res;
        this._userId = res.userId;
        if (!this.authData.username) this.authData.username = username;
        this._aliases = Array.isArray(res.aliases) ? res.aliases : [];

        localStorage.setItem("alertServerUser", JSON.stringify(res));
        this._connectEmaSync();
        var customerIds: string[] = [];

        const aliasUsers = this._aliases.map(alias => {

          // Collect customer IDs for alias features
          customerIds.push(alias.companyId.substring(8, 16));

          return VideoServerUser.fromObject(alias);
        }
        ).filter(user => !!user);

        this.videoServerService.trackVisits(aliasUsers);

        // Get customer alias features
        this.api.getCustomerAliasFeatures(customerIds).subscribe((res) => {
          localStorage.setItem("aliasFeatures", JSON.stringify(res));
        });

      } else {
        console.warn("Error: unable to authenticate. Retrying in 5 seconds...");
        this._evaluateOnlineState(true);
        setTimeout(() => this.connect(origUsername, origPassword, true), 5*1000);
      }
    }, _err => {
      console.warn("Error: unable to connect. Retrying in 5 seconds...");
      this._evaluateOnlineState(true);
      setTimeout(() => this.connect(origUsername, origPassword, true), 5*1000);
    });

    this.alertsObservable = new Observable(subscriber => {
      this.alertsSubscriber = subscriber;
    });
    this.teamObservable = new Observable(subscriber => {
      this.teamSubscriber = subscriber;
    });
    this.fwdAlertsObservable = new Observable(subscriber => {
      this.fwdAlertsSubscriber = subscriber;
    });
    this.onlineObservable = new Observable(subscriber => {
      this.onlineSubscriber = subscriber;
    });
    this.exitObservable = new Observable(subscriber => {
      this.exitSubscriber = subscriber;
    });

    return true;
  }

  //
  // Disconnect terminates all active sessions to alert server and video controller.
  //
  disconnect(): void {
    this._state = CrSyncState.Completed;
    this._userId = null;
    Object.keys(this.sessions).forEach((userId: string) => {
      this.sessions[userId]?.emaSync?.stop();
    });
    this.videoServerService.untrackVisits();
    this.sessions = {};
    if (this.alertsSubscriber) this.alertsSubscriber.complete();
    this.alertsSubscriber = null;
    this.alertsObservable = null;
    if (this.teamSubscriber) this.teamSubscriber.complete();
    this.teamSubscriber = null;
    this.teamObservable = null;
    if (this.fwdAlertsSubscriber) this.fwdAlertsSubscriber.complete();
    this.fwdAlertsSubscriber = null;
    this.fwdAlertsObservable = null;
    if (this.onlineSubscriber) this.onlineSubscriber.complete();
    this.onlineSubscriber = null;
    this.onlineObservable = null;
    if (this.exitSubscriber) this.exitSubscriber.complete();
    this.exitSubscriber = null;
    this.exitObservable = null;
  }

  //
  // Observable for receiving active alert events
  //
  get activeAlerts(): Observable<CrAlertEvent> {
    return this.alertsObservable;
  }

  //
  // Observable for receiving up-to-date team meber list
  //
  get teamMembers(): Observable<CrTeamMemberEvent> {
    return this.teamObservable;
  }

  //
  // Observable for receiving forwarded alert events
  //
  get forwardedAlerts(): Observable<CrAlertEvent> {
    return this.fwdAlertsObservable;
  }

  //
  // Observable for receiving online status events
  //
  get isOnline(): Observable<boolean> {
    return this.onlineObservable;
  }

  //
  // Observable for receiving exit events
  //
  get shouldExit(): Observable<void> {
    return this.exitObservable;
  }

  //
  // Internal state of the service
  //
  get state(): CrSyncState {
    return this._state;
  }

  //
  // User ID of the main user
  //
  get userId(): string {
    return this._userId;
  }

  //
  // List of user aliases. It is expected (but not validated) that
  // each entry should have userId property.
  //
  get aliases(): any[] {
    return this._aliases;
  }

  //
  // Return team member data, if synchronised. Note that the returned data
  // may later be updated as new state is synchronised from server.
  //
  getTeamMember(memberId: string, sessionUserId: string = null): CrTeamMember {
    let session: EmaSyncSession = null;
    if (sessionUserId) {
      session = this.sessions[sessionUserId];
      if (!session) return undefined;
      return session.teamMembers.find(entry => entry.userId === memberId);
    }

    // no session user id given --> search all sessions
    let member: CrTeamMember = undefined;
    Object.keys(this.sessions).some(sessionUserId => {
      member = this.sessions[sessionUserId]?.teamMembers?.find(entry => entry.userId === memberId);
      return !!member;
    });
    return member;
  }

  //
  // List of team members that are currently online.
  // Note: the requesting user is removed from the list.
  //
  getOnlineTeamMembers(sessionUserId: string): CrTeamMember[] {
    if (sessionUserId) {
      let session: EmaSyncSession = this.sessions[sessionUserId];
      if (!session) [];
      return session.teamMembers.filter(entry => entry.isOnline === true && entry.userId !== sessionUserId);
    }
    return [];
  }


  //
  // Request alert server to change alert's state to a new active state.
  //
  changeAlertState(userId: string, alertId: string, state: CrAlertState): boolean {
    // make shallow(ish) copy for provisional state change
    var alert = { ...this._wireAlerts[alertId]?.alert };
    alert.alertsrv = { ...this._wireAlerts[alertId]?.alert?.alertsrv };
    // set new state into copy
    switch(state) {
      case CrAlertState.Available:
        alert.alertsrv.state = "AVAILABLE";
        break;
      case CrAlertState.Reserved:
        alert.alertsrv.state = "RESERVED";
        break;
      case CrAlertState.Started:
        alert.alertsrv.state = "STARTED";
        break;
    }
    this._putAlert(userId, alert);
    return true;
  }

  //
  // Request alert server to change alert's state to completed.
  //
  completeAlert(userId: string, alertId: string): void {
    // make shallow(ish) copy for provisional state change
    var alert = { ...this._wireAlerts[alertId]?.alert };
    alert.alertsrv = { ...this._wireAlerts[alertId]?.alert?.alertsrv };
    // set completed state into copy
    alert.alertsrv.state = "COMPLETED";

    this._putAlert(userId, alert);
  }

  //
  // Request alert server to change alert's state to forwarded. Alert
  // server should delete the current alert and spawn a new alert
  // (with same alert ID) targeting forwardees (if Aspa is properly
  // configured).
  //
  forwardAlert(userId: string, alertId: string): void {
    this._putAlertRedirect(userId, alertId);
  }


  //
  // Request alert server to add a note to an alert
  //
  addNote(userId: string, alertId: string, noteText: string): void {
    this._postAlertNote(userId, alertId, noteText);
  }


  //
  // Request alert server to reset alert incrementer (counting additional
  // alerts from same device) back to zero.
  //
  resetAlertIncrement(userId: string, alertId: string): void {
    this._deleteAlertIncrementer(userId, alertId);
  }




  //
  // private methods
  //

  private _sync(esConfig: emasync.EmaSyncConfiguration): void {
    console.log(`Syncing: ${esConfig.userId}@${esConfig.companyId}`);

    let session = new EmaSyncSession(esConfig);
    this.sessions[esConfig.userId] = session;
    session.emaSync.start(esConfig, event => {
      // we are online (unless this is offline event, which is explicitly handled later)
      session.state = CrSyncState.Online;
      if (event.type === emasync.EmaSyncEventType.SYNC_ONLINE) {
        // already set to online --> do nothing
      } else if (event.type === emasync.EmaSyncEventType.SYNC_CONFIRMED) {
        // ping succeeded --> online (which is already set) --> do nothing
      } else if (event.type === emasync.EmaSyncEventType.SYNC_OFFLINE) {
        session.state = CrSyncState.Offline;
      } else if (event.type === emasync.EmaSyncEventType.SYNC_TERMINATED) {
        // send exit event
        if (this.exitSubscriber) this.exitSubscriber.next();
      } else if (event.type === emasync.EmaSyncEventType.ALERT_UPDATE_ALL) {
        if (event.data && Array.isArray(event.data)) {
          // update complete alerts associative array
          let otherSessionAlerts =  {};
          Object.keys(this._wireAlerts).forEach(id => {
            if (this._wireAlerts[id]?.userId !== esConfig.userId) otherSessionAlerts[id] = this._wireAlerts[id];
          });
          this._wireAlerts = otherSessionAlerts;
          event.data.forEach(entry => {
            if (this._isValidAlert(entry)) {
              this._wireAlerts[entry.alarmID] = new WireAlert(esConfig.userId, entry);
            }
          });
          // transform all wire alerts to a list of EmaAlerts, sort by alert time and push update
          let emaAlerts: CrAlert[] = Object.keys(this._wireAlerts).map(id =>
            this._makeEmaAlertFromWire(this._wireAlerts[id]?.userId, this._wireAlerts[id]?.alert)
          );
          emaAlerts.sort((a: CrAlert, b: CrAlert) => a.time.diff(b.time));
          this._pushAlerts(esConfig.userId, CrAlertEventType.UpdateAllAlerts, emaAlerts, null);
        }
      } else if (event.type === emasync.EmaSyncEventType.ALERT_UPDATE_ONE) {
        if (event.data) {
          // upsert new entry
          if (this._isValidAlert(event.data, false)) {
            if (event.data.alertsrv.state === "COMPLETED") {
              // remove completed alert
              delete this._wireAlerts[event.data.alarmID];
              this._pushAlerts(esConfig.userId, CrAlertEventType.DeleteOneAlert, null, event.data.alarmID);
            } else {
              // update active alert
              this._wireAlerts[event.data.alarmID] = new WireAlert(esConfig.userId, event.data);
              let emaAlert = this._makeEmaAlertFromWire(esConfig.userId, event.data);
              this._pushAlerts(esConfig.userId, CrAlertEventType.UpdateOneAlert, [emaAlert], null);
            }
          }
        }
      } else if (event.type === emasync.EmaSyncEventType.ALERT_DELETE_ONE) {
        if (event.data) {
          // delete alert
          delete this._wireAlerts[event.data];
          this._pushAlerts(esConfig.userId, CrAlertEventType.DeleteOneAlert, null, event.data);
        }
      } else if (event.type === emasync.EmaSyncEventType.ALERT_MONITOR_UPDATE_ALL) {
        if (event.data && Array.isArray(event.data)) {
          // update complete alerts associative array
          let otherSessionAlerts =  {};
          Object.keys(this._wireMonitoredAlerts).forEach(id => {
            if (this._wireMonitoredAlerts[id]?.userId !== esConfig.userId) otherSessionAlerts[id] = this._wireMonitoredAlerts[id];
          });
          this._wireMonitoredAlerts = otherSessionAlerts;
          event.data.forEach(entry => {
            if (this._isValidAlert(entry)) {
              this._wireMonitoredAlerts[entry.alarmID] = new WireAlert(esConfig.userId, entry);
            }
          });
          let emaAlerts: CrAlert[] = Object.keys(this._wireMonitoredAlerts).map(id =>
            this._makeEmaAlertFromWire(this._wireMonitoredAlerts[id]?.userId, this._wireMonitoredAlerts[id]?.alert, true)
          );
          emaAlerts.sort((a: CrAlert, b: CrAlert) => a.time.diff(b.time));
          this._pushFwdAlerts(esConfig.userId, CrAlertEventType.UpdateAllAlerts, emaAlerts, null);
        }
      } else if (event.type === emasync.EmaSyncEventType.ALERT_MONITOR_UPDATE_ONE) {
        if (event.data) {
          // upsert new entry
          if (this._isValidAlert(event.data, false)) {
            if (event.data.alertsrv.state === "COMPLETED") {
              // remove completed alert
              delete this._wireMonitoredAlerts[event.data.alarmID];
              this._pushFwdAlerts(esConfig.userId, CrAlertEventType.DeleteOneAlert, null, event.data.alarmID);
            } else {
              // update active alert
              this._wireMonitoredAlerts[event.data.alarmID] = new WireAlert(esConfig.userId, event.data);
              let emaAlert = this._makeEmaAlertFromWire(esConfig.userId, event.data, true);
              this._pushFwdAlerts(esConfig.userId, CrAlertEventType.UpdateOneAlert, [emaAlert], null);
            }
          }
        }
      } else if (event.type === emasync.EmaSyncEventType.ALERT_MONITOR_DELETE_ONE) {
        if (event.data) {
          // delete entry
          delete this._wireMonitoredAlerts[event.data];
          this._pushFwdAlerts(esConfig.userId, CrAlertEventType.DeleteOneAlert, null, event.data);
        }
      } else if (event.type === emasync.EmaSyncEventType.TEAM_SYNC_NEEDED ||
                  event.type === emasync.EmaSyncEventType.TEAM_MEMBER_ONLINE_STATE_SYNC ||
                  event.type === emasync.EmaSyncEventType.TEAM_MEMBER_PROPERTY_SYNC) {
        this._handleMemberEvent(esConfig.userId, session, event);
      }

      //
      this._evaluateOnlineState();
    });
  }

  private _evaluateOnlineState(reconnecting: boolean = false): void {
    // check online state of all sessions; service state is online only when all sessions are online

    // do not evaluate in initial/terminal states
    const noEvalStates = [CrSyncState.Error, CrSyncState.Init, CrSyncState.Completed];
    if (noEvalStates.lastIndexOf(this._state) !== -1) return;

    // handle reconnecting special case
    if (reconnecting)  {
      if (this.state === CrSyncState.Connecting) {
        // first reconnect --> set offline
        if (this.onlineSubscriber) this.onlineSubscriber.next(false);
      }
      return;
    }

    // evaluate online state of service
    var previousState = this._state;
    let isOnline = Object.keys(this.sessions).every((userId: string) =>
        this.sessions[userId]?.state === CrSyncState.Online || this.sessions[userId]?.state === CrSyncState.Connecting);
    this._state = isOnline ? CrSyncState.Online : CrSyncState.Offline;

    // send event on changes
    if (previousState !== CrSyncState.Online && this._state === CrSyncState.Online) {
      // became online --> send online event
      if (this.onlineSubscriber) this.onlineSubscriber.next(true);
    } else if (previousState !== CrSyncState.Offline && this._state === CrSyncState.Offline) {
      // became offline --> send online event
      if (this.onlineSubscriber) this.onlineSubscriber.next(false);
    }
  }

  private _connectEmaSync(): void {
    // connect with emasync
    let esConfig = {
      url: this.asBaseUrl,
      userId: this.authData.userId,
      companyId: this.authData.companyId,
      authToken: this.authData.authToken,
      info: {},
      reloginFn: () => console.log("Relogin not implemented"),
      pingPeriodSec: 60,
      pingPeriodTimeoutSec: 10,
      getAlertsMaxDelaySec: 15,
      offlineDelaySec: 10,
      alwaysGetMonitoredAlerts: true,
      verbose: false
    };
    this._sync(esConfig);

    if (this._aliases) {
      // make alias connections, too
      this._aliases.forEach(alias => {
        if (!alias.userId || !alias.companyId || !alias.authToken) return;
        let aliasConfig = {...esConfig};
        aliasConfig.userId = alias.userId;
        aliasConfig.companyId = alias.companyId;
        aliasConfig.authToken = alias.authToken;
        this._sync(aliasConfig);
      });

    }
  }

  private _isValidAlert(alert: any, filterCompleted: boolean = true): boolean {
    return (alert.alarmID &&
            alert.companyID &&
            alert.alertsrv.state &&
            (!filterCompleted || alert.alertsrv.state !== "COMPLETED"));
  }

  private _pushAlerts(userId: string, type: CrAlertEventType, alerts: CrAlert[], alertId: string): void {
    if (!alerts) alerts = [];
    if (this.alertsSubscriber) this.alertsSubscriber.next(new CrAlertEvent(userId, type, alerts, alertId));
  }

  private _pushFwdAlerts(userId: string, type: CrAlertEventType, alerts: CrAlert[], alertId: string): void {
    if (!alerts) alerts = [];
    if (this.fwdAlertsSubscriber) this.fwdAlertsSubscriber.next(new CrAlertEvent(userId, type, alerts, alertId));
  }

  private _makeEmaAlertFromWire(userId: string, wireAlert: any, monitoredAlert: boolean = false): CrAlert {
    let _addressFromAlert = (alert: any): string => {
      if (alert.postAddress && alert.postCode && alert.postOffice) {
        return alert.postAddress + ", " + alert.postCode + " " + alert.postOffice
      }
      return "";
    };
   let  _stateFromAlert = (alert: any): CrAlertState => {
      if (alert.alertsrv.state === "STARTED") return CrAlertState.Started;
      if (alert.alertsrv.state === "RESERVED") return CrAlertState.Reserved;
      return CrAlertState.Available;
    };
    return new CrAlert(
      userId,
      wireAlert.alarmID,
      wireAlert.alertRoutingType === 'Verifi' ? moment(wireAlert.alertsrv.receivedTime) : moment(wireAlert.alarmTime),
      wireAlert.deviceName,
      wireAlert.deviceID,
      wireAlert.info || "",
      wireAlert.alertsrv.reservedBy,
      wireAlert.alertsrv.startedBy,
      wireAlert.reRoutedBy,
      _stateFromAlert(wireAlert),
      monitoredAlert,
      _addressFromAlert(wireAlert),
      wireAlert.devicePrefixClass,
      wireAlert.alarmGroup,
      wireAlert.alarmNode,
      wireAlert.companyID,
      wireAlert.companyName || "",
      wireAlert.baseMISDN,
      wireAlert.place,
      wireAlert.alertsrv.alertIncrement,
      !monitoredAlert,
      false,
      wireAlert.alertsrv.notes,
      wireAlert.alarmReasons,
      wireAlert.locationName,
      wireAlert.positionLink,
      wireAlert.positionLatitude,
      wireAlert.positionLongitude,
      wireAlert.positionPrecision,
      wireAlert.positionType,
    );
  }

  private _handleMemberEvent(userId: string, session: EmaSyncSession, event: emasync.EmaSyncEvent) {
    var __handleNextEvent = () => {
      // this is a (not tail-call optimised) recursive function
      // --> all paths should drop handled event from buffer before recursing
      //     i.e. session.teamSyncEventBuffer.shift()

      if (session.teamSyncEventBuffer.length === 0) {
        // all events handled --> send update to observers
        if (this.teamSubscriber) this.teamSubscriber.next(new CrTeamMemberEvent(userId, session.teamMembers));
        return;
      }

      // handle next event from buffer
      var event = session.teamSyncEventBuffer[0];
      if (event.type === emasync.EmaSyncEventType.TEAM_SYNC_NEEDED) {
        this._getTeamMembers(userId).subscribe(
          (data: any) => {
            if (data && Array.isArray(data)) {
              // mutate existing objects by replacing existing, adding new ones and then removing deleted
              data.forEach(member => {
                let found = false;
                session.teamMembers.some((existingMember: CrTeamMember, ix: number) => {
                  found = (member._id === existingMember.userId);
                  if (found) session.teamMembers[ix] = this._makeMemberFromWire(member);
                  return found;
                });
                if (!found) session.teamMembers.push(this._makeMemberFromWire(member));
              });
              session.teamMembers.forEach((m: CrTeamMember, ix: number) => {
                let found = data.some(newMember => newMember._id === m.userId);
                if (!found) session.teamMembers.splice(ix, 1);
              });
              session.teamMembers.sort();
              session.teamSyncEventBuffer.shift();
              __handleNextEvent();
            }
          },
          (_error: any) => {
            session.teamSyncEventBuffer.shift();
            __handleNextEvent();
          }
        );
      } else if (event.type === emasync.EmaSyncEventType.TEAM_MEMBER_ONLINE_STATE_SYNC) {
        if (event.data && typeof event.data.userId === "string" && event.data.userId !== userId && typeof event.data.connected === "boolean") {
          session.teamMembers = session.teamMembers.map(member => {
            if (member.userId === event.data.userId) {
              return new CrTeamMember(member.userId, member.name, member.initials, event.data.connected, member.phoneNumbers, member.photoAvatar);
            } else {
              return member;
            }
          });
          session.teamSyncEventBuffer.shift();
          __handleNextEvent();
        }
      } else if (event.type === emasync.EmaSyncEventType.TEAM_MEMBER_PROPERTY_SYNC) {
        if (event.data && event.data.user && typeof event.data.user._id === "string" && event.data.userId !== userId) {
          session.teamMembers = session.teamMembers.map(member => {
            if (member.userId === event.data.user._id) {
              var newMember = this._makeMemberFromWire(event.data.user);
              return new CrTeamMember(member.userId, newMember.name, newMember.initials, member.isOnline, newMember.phoneNumbers, newMember.photoAvatar);
            } else {
              return member;
            }
          });
          session.teamSyncEventBuffer.shift();
          __handleNextEvent();
        }
      } else {
        // this branch should not happen
        console.warn("Not handled:", userId, event);
        session.teamSyncEventBuffer.shift();
        __handleNextEvent();
      }
    }

    // first buffer event for handling
    session.teamSyncEventBuffer.push(event);
    if (session.teamSyncEventBuffer.length > 1) {
      // earlier event is being handled --> current event will be handled later
      return;
    }
    // handle recursively immediate event and events buffered during handling
    __handleNextEvent();
  }

  private _makeMemberFromWire(wireMember: any): CrTeamMember {
    if (!wireMember || !wireMember._id) return;

    let name = "N/A";
    let initials = "";
    if (wireMember.firstname && wireMember.lastname) {
      name = wireMember.lastname + ", " + wireMember.firstname
      initials = wireMember.firstname.charAt(0) + wireMember.lastname.charAt(0);
    } else if (wireMember.firstname) {
      name = wireMember.firstname;
      initials = wireMember.firstname.charAt(0);
    } else if (wireMember.lastname) {
      name = wireMember.lastname;
      initials = wireMember.lastname.charAt(0);
    }
    let phoneNumbers = [];
    if (wireMember.alertsrv && wireMember.alertsrv.simCards && Array.isArray(wireMember.alertsrv.simCards)) {
      wireMember.alertsrv.simCards.forEach(tel => {
        let displayName = tel.displayName || "N/A";
        if (tel.phoneNumber) phoneNumbers.push(new CrPhoneNumber(displayName, tel.phoneNumber));
      });
    }
    let isOnline = false;
    if (wireMember.alertsrv && typeof wireMember.alertsrv.present === "boolean") {
      isOnline = wireMember.alertsrv.present;
    }
    let photoAvatar: string = undefined;
    if (wireMember.alertsrv && typeof wireMember.alertsrv.photoAvatar === "string") {
      photoAvatar = wireMember.alertsrv.photoAvatar;
    }

    return new CrTeamMember(
      wireMember._id,
      name,
      initials,
      isOnline,
      phoneNumbers,
      photoAvatar
    );
  }

  private _getUsernameWithId(userId: string): string {
    if (userId === this._userId) {
      // main user
      return this.authData.username || userId;
    }

    let username = "";
    this._aliases.some(alias => {
      let found = (userId === alias.userId);
      if (found) {
        username = alias.username;
      }
      return found;
    });
    return username || userId;
  }

  private _putAlert(userId: string, alert: any): void {
    let session = this.sessions[userId];
    if (!session) return;

    let url = this.asBaseUrl + '/api/alerts/' + alert.alarmID;
    let data = alert;
    let options = { headers: session.sessionHeaders };
    this.http.put(url, data, options).subscribe();
  }

  private _putAlertRedirect(userId: string, alertId: string): void {
    let session = this.sessions[userId];
    if (!session) return;
    let url = this.asBaseUrl + '/api/alerts/' + alertId + '/redirect';
    let data = {};
    let options = { headers: session.sessionHeaders };
    this.http.put(url, data, options).subscribe();
  }

  private _getTeamMembers(userId: string): Observable<any>  {
    let session = this.sessions[userId];
    if (!session) return;
    let url = this.asBaseUrl + '/api/users';
    let options = { headers: session.sessionHeaders };
    return this.http.get(url, options);
  }

  private _postAlertNote(userId: string, alertId: string, noteText: string): void  {
    let session = this.sessions[userId];
    if (!session) return;

    let url = this.asBaseUrl + '/api/alerts/' + alertId + '/notes';
    let data = {
      writtenBy: this._getUsernameWithId(session.config.userId),
      time: new Date().toISOString(),
      entryMethod: "WRITTEN",
      text: noteText
    };
    let options = { headers: session.sessionHeaders };
    this.http.post(url, data, options).subscribe();
  }

  private _deleteAlertIncrementer(userId: string, alertId: string): void  {
    let session = this.sessions[userId];
    if (!session) return;

    let url = this.asBaseUrl + '/api/alerts/' + alertId + '/incrementer';
    let options = { headers: session.sessionHeaders };
    this.http.delete(url, options).subscribe();
  }

}



//
// LOCAL CLASSES
//



//
// Session that is established with alert server
// using emasync module.
//
// There are typically multiple simultaneous sessions:
// one for main user account and one for each user alias.
//
class EmaSyncSession {
  constructor(config: emasync.EmaSyncConfiguration) {
    this.config = config;
    this.emaSync = new emasync.EmaSync();
    this.state = CrSyncState.Connecting;
    this.sessionHeaders = {
      "x-alertsrv-authtoken": config.authToken,
      "x-alertsrv-sessionid": config.authToken,
      "x-everon-companyid": config.companyId
    }
    this.teamMembers = [];
    this.teamSyncEventBuffer = [];
  }
  config: emasync.EmaSyncConfiguration;
  emaSync: emasync.EmaSync;
  state: CrSyncState;
  sessionHeaders: any;
  teamMembers: CrTeamMember[];
  teamSyncEventBuffer: emasync.EmaSyncEvent[];
}


//
// Pair of user ID and alert that is used for caching
// wire alerts for passing complete alerts back to
// alert server REST API
//
class WireAlert {
  constructor(
    public userId: string,
    public alert: any
  ) {}
}
