<div class="row">
    <div class="col-12 ">
        <alarm-route-recipients
          [tempRoute]="tempRoute"
          [fallbackRecipients]="true"
          [allStaff]="allStaff"
          [allReceiverTeams]="allReceiverTeams"
          [customerInformation]="customerInformation">
        </alarm-route-recipients>
    </div>
</div>
