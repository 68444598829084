import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AlarmIndicationGroup, Route } from '../../../../models/alarmroutes.model';
import { Device } from '../../../../models/device.model';
import { ApiService } from '../../../../services/api.service';
import { ActivationOption } from '../alarm-route-devices/alarm-route-devices.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLineModule, MatOptionModule } from '@angular/material/core';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-alarm-route-alarm-indication-groups',
  templateUrl: './alarm-route-alarm-indication-groups.component.html',
  styleUrls: ['./alarm-route-alarm-indication-groups.component.css'],
  standalone: true,
  imports: [MatListModule, NgIf, MatLineModule, MatIconModule, NgFor, NgClass, MatSlideToggleModule, MatFormFieldModule, MatSelectModule, MatOptionModule, TranslateModule]
})
export class AlarmRouteAlarmIndicationGroupsComponent implements OnInit, OnChanges {

  constructor(private api: ApiService) { }


  @Input('tempRoute') tempRoute: Route;
  @Input('deviceList') rawDeviceList: Device[];

  deviceGroups: DeviceGroup[] = [];
  selectedDeviceForSettings: DeviceOption;

  getDeviceGroups(rawDeviceList: Device[]): DeviceGroup[] {

    let deviceGroup: DeviceGroup[] = [];


    // Filter basestations
    rawDeviceList = rawDeviceList.filter(device => device.deviceType.startsWith('31') && !device.deviceType.startsWith('31-6'));

    // Filter by location
    if (this.tempRoute.locationId.startsWith('C22')) {
      rawDeviceList = rawDeviceList.filter(device => device.locationId === this.tempRoute.locationId);
    }


    const deviceList: DeviceOption[] = rawDeviceList.map(device => {
      return {
        baseStationId: device.id,
        deviceType: device.deviceType,
        baseStationName: device.name,
        locationId: device.locationId,
        locationName: device.locationName,
        buzzerOnly: true,
        alarmOnRoamingBase: false,
        buzzerAndLed: false,
        alarmOnHomeBase: true
      }
    });

    deviceList.forEach(dev => {
      dev.selected = false;

      let deviceGroupIndex: number = deviceGroup.findIndex(deviceGroup => deviceGroup.locationId === dev.locationId);

      if (deviceGroupIndex === -1) {
        deviceGroup.push({ locationId: dev.locationId, locationName: dev.locationName, deviceOptions: [dev] })
      } else {
        deviceGroup[deviceGroupIndex].deviceOptions.push(dev);
      }

    });

    deviceGroup = deviceGroup.sort((a, b) => a.locationName.localeCompare(b.locationName));

    return deviceGroup;

  }

  // Gets devices from deviceGroups
  getDevicesFromGroup(): DeviceOption[] {

    let devices: DeviceOption[] = [];

    this.deviceGroups.forEach(group => {
      group.deviceOptions.forEach(device => {
        devices.push(device);

      });
    });

    return devices;

  }


  getDeviceType(type: string): string {
    return this.api.getDeviceType(type);
  }

  getDeviceIconClass(deviceType: string): string {
    return this.api.getDeviceIconClass(deviceType);
  }


  // Adds Devices
  addDevice(device: DeviceOption): void {
    device.selected = true;
    this.saveDevicesToTempRoute();
  }


  addAllDevices(): void {
    const devices = this.getDevicesFromGroup();
    devices.forEach(device => {
      this.addDevice(device);
    });
  }


  addDevicesByLocationId(locationId: string): void {
    const devices = this.getDevicesFromGroup().filter(device => device.locationId === locationId);
    devices.forEach(device => this.addDevice(device));
  }

  // Removes Devices
  removeDevice(device: DeviceOption): void {
    device.selected = false;
    this.saveDevicesToTempRoute();
  }


  removeAllDevices(): void {
    const devices = this.getDevicesFromGroup();
    devices.forEach(device => {
      this.removeDevice(device);
    });
  }

  removeDevicesByLocationId(locationId: string): void {
    const devices = this.getDevicesFromGroup().filter(device => device.locationId === locationId);
    devices.forEach(device => this.removeDevice(device));
  }



  // Check if there are no available devices (device.selected = false) in deviceGroups
  availableDevicesEmpty(): boolean {
    const devices = this.getDevicesFromGroup().filter(device => !device.selected);
    return devices && devices.length !== 0;
  }

  // Check if there are no selected devices (device.selected = true) in deviceGroups
  selectedDevicesEmpty(): boolean {
    const devices = this.getDevicesFromGroup().filter(device => device.selected);
    return devices && devices.length !== 0;
  }


  // Check if there is deviceId (device.selected = false) in deviceGroups
  availableDeviceByDeviceId(deviceId: string): boolean {
    const device = this.getDevicesFromGroup().find(device => !device.selected && device.baseStationId === deviceId);
    return !!device;
  }


  // Check if there is deviceId (device.selected = true) in deviceGroups
  selectedDeviceByDeviceId(deviceId: string): boolean {
    const device = this.getDevicesFromGroup().find(device => device.selected && device.baseStationId === deviceId);
    return !!device
  }


  // Check if there are any devices ny locationId (device.selected = false) in deviceGroups
  availableDevicesInLocation(locationId: string): boolean {
    const devices = this.getDevicesFromGroup().filter(device => !device.selected && device.locationId === locationId);
    return devices && devices.length !== 0;
  }


  // Check if there are any devices by locationId (device.selected = true) in deviceGroups
  selectedDevicesInLocation(locationId: string,): boolean {
    const devices = this.getDevicesFromGroup().filter(device => device.selected && device.locationId === locationId);
    return devices && devices.length !== 0;
  }


  highlightDeviceOption(deviceOption: DeviceOption): void {
    const devices = this.getDevicesFromGroup().filter(device => device.selected);
    devices.forEach(device => device.highlight = false);

    deviceOption.highlight = true;
  }


  changeHighlightedBaseStationBuzzerOption(buzzerOptionChecked: boolean): void {
    this.selectedDeviceForSettings.buzzerAndLed = buzzerOptionChecked;
    this.selectedDeviceForSettings.buzzerOnly = !buzzerOptionChecked;
    this.saveDevicesToTempRoute();
  }

  getHighlightedBaseStationAlarmOption(): AlarmOption {

    if (!this.selectedDeviceForSettings.alarmOnHomeBase && !this.selectedDeviceForSettings.alarmOnRoamingBase) {
      return AlarmOption.AlarmOnHomeBase
    }

    if (this.selectedDeviceForSettings.alarmOnHomeBase && !this.selectedDeviceForSettings.alarmOnRoamingBase) {
      return AlarmOption.AlarmOnHomeBase
    }

    if (!this.selectedDeviceForSettings.alarmOnHomeBase && this.selectedDeviceForSettings.alarmOnRoamingBase) {
      return AlarmOption.AlarmOnRoamingBase
    }

    if (this.selectedDeviceForSettings.alarmOnHomeBase && this.selectedDeviceForSettings.alarmOnRoamingBase) {
      return AlarmOption.AlarmOnBoth
    }
  }


  changeHighlightedBaseStationAlarmOption(alarmOnHomeBaseSelected: boolean, alarmOnRoamingBaseSelected: boolean, alarmOnBothSelected: boolean): void {

    if (alarmOnHomeBaseSelected) {
      this.selectedDeviceForSettings.alarmOnHomeBase = true;
      this.selectedDeviceForSettings.alarmOnRoamingBase = false;
    }

    if (alarmOnRoamingBaseSelected) {
      this.selectedDeviceForSettings.alarmOnHomeBase = false;
      this.selectedDeviceForSettings.alarmOnRoamingBase = true;
    }

    if (alarmOnBothSelected) {
      this.selectedDeviceForSettings.alarmOnHomeBase = true;
      this.selectedDeviceForSettings.alarmOnRoamingBase = true;
    }
    this.saveDevicesToTempRoute();
  }


  addInitialDevices(): void {
    if (this.tempRoute.alarmIndicationGroups) {
      const devices = this.getDevicesFromGroup();
      if (devices) {
        this.tempRoute.alarmIndicationGroups.forEach(initialDevice => {
          const device = devices.find(deviceOption => deviceOption.baseStationId === initialDevice.baseStationId);

          if (device) {
            this.addDevice(device);
          }
        });
      }
    }
  }

  saveDevicesToTempRoute(): void {
    this.tempRoute.alarmIndicationGroups = this.getDevicesFromGroup().filter(device => device.selected);
  }

  ngOnInit() {
    // Deep copy rawDeviceList so that we won't keep the reference
    this.deviceGroups = this.getDeviceGroups(JSON.parse(JSON.stringify(this.rawDeviceList)));

    this.addInitialDevices();
  }

  // Re-parse devices on location change
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.tempRoute.previousValue) {
      const tempDeviceList = this.getDevicesFromGroup().filter(device => device.selected);

      this.deviceGroups = this.getDeviceGroups(JSON.parse(JSON.stringify(this.rawDeviceList)));

      this.tempRoute.alarmIndicationGroups = tempDeviceList.filter(device => this.availableDeviceByDeviceId(device.baseStationId));

      this.addInitialDevices();

    }
  }
}


export interface DeviceOption extends AlarmIndicationGroup {
  locationName?: string;
  highlight?: boolean;
  selected?: boolean;
}


export interface DeviceGroup {
  locationName: string;
  locationId: string;
  deviceOptions: DeviceOption[];
}


enum AlarmOption {
  AlarmOnHomeBase,
  AlarmOnRoamingBase,
  AlarmOnBoth
}
