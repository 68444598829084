import { Component, Inject, OnInit } from '@angular/core';
import { ValidationError, ValidationModel } from '../../../../models/alarmroutes.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { AlarmRouteEditComponent } from '../alarm-route-edit.component';
import { Device } from '../../../../models/device.model';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { KeyValuePipe, CommonModule } from '@angular/common';
import { DeviceIconClassPipe, DeviceTypePipe, FindDeviceName, FindDeviceType } from 'app/pipes/pipes';


@Component({
  selector: 'validation-dialog-content',
  templateUrl: './validation-dialog-content.component.html',
  styleUrls: ['./validation-dialog-content.component.css'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatListModule, KeyValuePipe, DeviceTypePipe, DeviceIconClassPipe, FindDeviceType, FindDeviceName, TranslateModule]
})
export class ValidationDialogContentComponent implements OnInit {
  validationModel: ValidationModel;

  validationErrorsTranslationMap: Map<string, string> = new Map([
    ['DefaultRouterDeviceOrServiceTimesNotEmpty', 'DEFAULT_ROUTE_DEVICE_OR_SERVICE_TIME_NOT_EMPTY'],
    ['InvalidServiceTimesDayAmount', 'INVALID_SERVICE_TIMES_DAY_AMOUNT'],
    ['DevicesMissing', 'MISSING_DEVICES'],
    ['DeliveryTypeNotValidForEmaFallback', 'DELIVERY_TYPE_NOT_VALID_EMA'],
    ['EmaFallbackTypeNotValidForCallCenter', 'EMA_FALLBACK_TYPE_NOT_VALID_EMA_CALL_CENTER'],
    ['AddingDefaultRouterNotAllowed', 'CREATE_DEFAULT_ROUTE_NOT_ALLOWED'],
    ['NameAlreadyInUse', 'NAME_IN_USE'],
    ['RecipientsMissing', 'MISSING_RECIPIENTS'],
    ['ActivationsForDeviceMissing', 'ACTIVATIONS_FOR_DEVICE_MISSING'],
    ['ConlictingRoutersFound', 'CONFLICTING_ROUTERS'],
  ]);

  daysMap: Map<string, string> = new Map([
    ['mon', 'DAYOFWEEK_1'],
    ['tue', 'DAYOFWEEK_2'],
    ['wed', 'DAYOFWEEK_3'],
    ['thu', 'DAYOFWEEK_4'],
    ['fri', 'DAYOFWEEK_5'],
    ['sat', 'DAYOFWEEK_6'],
    ['sun', 'DAYOFWEEK_7'],
  ]);

  validationErrorGroups: Map<ValidationError, any> = new Map();
  validationError = ValidationError;

  deviceMap: Map<string, Device>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { validationModel: ValidationModel, deviceMap: Map<string, Device> },
    public dialogRef: MatDialogRef<AlarmRouteEditComponent>,
    private router: Router
  ) { }

  ngOnInit() {
    this.validationModel = this.data.validationModel;
    this.deviceMap = this.data.deviceMap;
    this.createErrorGroups(this.validationModel);
  }

  createErrorGroups(validationModel: ValidationModel): void {
    validationModel.errors?.forEach(error => {
      const errorName = ValidationError[<ValidationError>this.getError(error)];

      switch (errorName) {
        case ValidationError.ActivationsForDeviceMissing: {
          const deviceActivationError = this.parseMissingDeviceActivationData(error);

          if (this.validationErrorGroups.has(errorName)) {
            this.validationErrorGroups.get(errorName).push(deviceActivationError);
          } else {
            this.validationErrorGroups.set(errorName, [deviceActivationError]);
          }
          break;
        }
        case ValidationError.ConlictingRoutersFound: {
          this.validationErrorGroups.set(errorName, validationModel.conflicts);
          break;
        }
        default: {
          this.validationErrorGroups.set(errorName, null);
          break;
        }
      }
    });
  }

  getError(error: string): string {
    const index = error.indexOf('|');
    if (index !== -1) {
      error = error.substring(0, index);
    }
    return error;
  }

  parseMissingDeviceActivationData(error: string): DeviceActivationError {
    const index = error.indexOf('|');

    if (index !== -1) {
      const errorData = error.split('|').splice(1);
      return { deviceId: errorData[0], deviceName: errorData[2], deviceType: errorData[1] }
    }

    return;
  }

  // 00:00:00 => 00:00
  trimTime(time: string): string {
    return time.substring(0, Math.max(time.length - 3, 5));
  }

  openRoutePage(routeId: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['route/' + routeId]));
    window.open(url, '_blank');
  }
}

interface DeviceActivationError {
  deviceName: string;
  deviceId: string;
  deviceType: string;
}

