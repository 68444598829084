<div *ngIf="locationSettings">
    <div class="mb-3">
        <ng-container *ngIf="location.id.startsWith('C0');else elseBlock">
            {{ 'SET_DEFAULT_SETTINGS_FOR_EMA' | translate }}
        </ng-container>

        <ng-template #elseBlock>
            <!-- Hide this for now, as the functionality cannot be implemented yet
            <mat-checkbox id="default-ema-settings-checkbox" (change)="changeDefaultSettings($event)">
                {{ 'USE_CUSTOMER_DEFAULT_SETTINGS_FOR_EMA' | translate }}
            </mat-checkbox>
            -->
        </ng-template>
    </div>

    <mat-accordion multi="true">
        <!-- Alert Timeout category -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span><span class="fa-light fa-fw fa-clock"></span> {{'ALERT_TIMEOUTS' |translate}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="alertTimeoutsForm" (ngSubmit)="formSubmitSubject.next('submitAlertTimeoutsForm')">
                <div class="form-group">
                    <label>{{'RESERVED_TIMEOUT' |translate}}</label>
                    <mat-form-field>
                        <mat-select formControlName="reserved">
                            <mat-option *ngFor="let option of locationSettings.alertTimers.reserved.options"
                                [value]="option">
                                {{option}} {{'MINUTES' |translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label>{{'STARTED_TIMEOUT' |translate}}</label>
                    <mat-form-field>
                        <mat-select formControlName="started">
                            <mat-option *ngFor="let option of locationSettings.alertTimers.started.options"
                                [value]="option">
                                {{option}} {{'MINUTES' |translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label>{{'COMPLETED_TIMEOUT' |translate}}</label>
                    <mat-form-field>
                        <mat-select formControlName="completed">
                            <mat-option *ngFor="let option of locationSettings.alertTimers.completed.options"
                                [value]="option">
                                {{option}} {{'MINUTES' |translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="!defaultConfigChecked && !viewOnly">
                    <button type="submit" class="btn btn-primary">
                        <span class="fa-light fa-fw fa-floppy-disk"></span>
                        {{'SAVE' |translate}}
                    </button>
                    <div class="alert alert-success mt-3" *ngIf="saveState.alertTimers === 1">
                        {{'CONF_SAVED' |translate}}
                    </div>
                    <div class="alert alert-danger mt-3" *ngIf="saveState.alertTimers === 2">
                        {{'ERROR' |translate}}
                    </div>
                </div>
            </form>
        </mat-expansion-panel>
        <!-- Alert priorities category -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span><span class="fa-light fa-fw fa-flag"></span> {{'ALERT_PRIORITIES' |translate}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-accordion multi="true">
                <mat-expansion-panel *ngFor="let priority of locationSettings.alertPriorities.priorities">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span><span class="fa-solid fa-fw fa-flag" [style.color]="priority.colour"></span>
                                {{'PRIORITY' |translate}} {{priority.priority}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <mat-form-field>
                            <mat-select [(ngModel)]="priority.sound" [disabled]="defaultConfigChecked || viewOnly" name="prioritySound"
                                (selectionChange)="playPrioritySound(priority.sound)">
                                <mat-option *ngFor="let option of soundOptions" [value]="option">
                                    {{option |translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field>
                            <mat-select [(ngModel)]="priority.colour" [disabled]="defaultConfigChecked || viewOnly" name="priorityColour">
                                <mat-option *ngFor="let option of colourOptions" [value]="option">
                                    <span class="fa-solid fa-fw fa-circle" [style.color]='option'></span> {{option
                                    |translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-selection-list dense="true" [disabled]="defaultConfigChecked || viewOnly" (selectionChange)="alertTypeSelectionChange($event, priority)">
                            <mat-list-option [disabled]="type.disabled" [selected]="type.selected"
                                *ngFor="let type of priority.availableAlertTypes" [value]="type.value">
                                {{type.value |translate}}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="mt-3" *ngIf="!defaultConfigChecked && !viewOnly">
                <!-- Placeholder element for playing priority sound files -->
                <audio id="priorityAudio"></audio>

                <button class="btn btn-outline-primary gray-bg" (click)="submitAlertPriorities()">
                    <span class="fa-light fa-fw fa-circle-plus"></span>
                    {{'SAVE' |translate}}
                </button>

                <div class="alert alert-success mt-3" *ngIf="saveState.alertPriorities === 1">
                    {{'CONF_SAVED' |translate}}
                </div>

                <div class="alert alert-danger mt-3" *ngIf="saveState.alertPriorities === 2">
                    {{'ERROR' |translate}}
                </div>
            </div>
        </mat-expansion-panel>
        <!-- Alert notes category -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>
                        <span class="fa-light fa-fw fa-note-sticky"></span>
                        {{ (viewOnly ? 'NOTES' : 'ALERT_NOTE_TEMPLATES') |translate}}
                    </span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="locationSettings.alertNotes.notes.length === 0">
                <p>{{'NO_NOTES' |translate}}</p>
            </div>
            <div *ngIf="locationSettings.alertNotes.notes.length > 0">
                <ul class="alertNotesList">
                    <li *ngFor="let note of locationSettings.alertNotes.notes; let i = index">
                        <div class="noteBody float-left">
                            {{note}}
                        </div>
                        <div *ngIf="!viewOnly" class="float-left">
                            <span class="fa-light fa-fw fa-lg fa-trash-alt cursorPointerHover"
                                (click)="removeNote(i)">
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <form [formGroup]="alertNoteForm" *ngIf="!viewOnly" (ngSubmit)="formSubmitSubject.next('submitAlertNoteForm')" class="mt-3">
                <div class="form-group">
                    <textarea class="form-control" formControlName="note"></textarea>
                </div>

                <div class="form-group">
                    <button [disabled]="!alertNoteForm.valid" class="btn btn-outline-primary gray-bg">
                        <span class="fa-light fa-fw fa-circle-plus"></span>
                        {{'ADD_NEW_NOTE_TEMPLATE' |translate}}
                    </button>

                    <div class="alert alert-danger mt-3" *ngIf="saveState.alertNotes === 2">
                        {{'ERROR' |translate}}
                    </div>
                </div>
            </form>
        </mat-expansion-panel>
        <!-- Alarm options category -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span><span class="fa-light fa-fw fa-bell"></span> {{'ALARM_OPTIONS' |translate}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="alarmOptionsForm" (ngSubmit)="formSubmitSubject.next('submitAlarmOptionsForm')">
                <div class="form-group">
                    <label>{{'OFFLINE_DELAY' |translate}}</label>
                    <mat-form-field>
                        <mat-select formControlName="offlineDelay">
                            <mat-option *ngFor="let option of locationSettings.alarmOptions.offlineDelay.options"
                                [value]="option">
                                {{getOfflineDelayOption(option)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label>{{'STARTED_TASK_MUTING' |translate}}</label>
                    <mat-form-field>
                        <mat-select formControlName="startedTaskMuting">
                            <mat-option *ngFor="let option of locationSettings.alarmOptions.startedTaskMuting.options"
                                [value]="option">
                                {{option |translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label>{{'MINIMUM_VOLUME' |translate}}</label>
                    <mat-form-field>
                        <mat-select formControlName="minimumVolume">
                            <mat-option *ngFor="let option of locationSettings.alarmOptions.minimumVolume.options"
                                [value]="option">
                                {{option}} %
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="!defaultConfigChecked && !viewOnly">
                    <button type="submit" class="btn btn-primary"><span class="fa-light fa-fw fa-floppy-disk"></span>
                        {{'SAVE'
                        |translate}}</button>
                    <div class="alert alert-success mt-3" *ngIf="saveState.alarmOptions === 1">
                        {{'CONF_SAVED' |translate}}
                    </div>
                    <div class="alert alert-danger mt-3" *ngIf="saveState.alarmOptions === 2">
                        {{'ERROR' |translate}}
                    </div>
                </div>
            </form>
        </mat-expansion-panel>
        <!-- Alert UI category -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span><span class="fa-light fa-fw fa-tv"></span> {{'ALERT_UI_SETTINGS' | translate}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="alertUiForm" (ngSubmit)="formSubmitSubject.next('submitAlertUiForm')">
                <div class="form-group">
                    <label>{{'QUICK_ALERT_COMPLETE' | translate}}</label>
                    <mat-form-field>
                        <mat-select formControlName="quickComplete">
                            <mat-option *ngFor="let option of locationSettings.alertUi.quickComplete.options"
                                [value]="option">
                                {{option | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label>{{'ALERT_CARD_HEADER' | translate}}</label>
                    <mat-form-field>
                        <mat-select formControlName="alertCardHeader">
                            <mat-option *ngFor="let option of locationSettings.alertUi.alertCardHeader.options"
                                [value]="option">
                                {{option | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="!defaultConfigChecked && !viewOnly">
                    <button type="submit" class="btn btn-primary"><span class="fa-light fa-fw fa-floppy-disk"></span>
                        {{'SAVE'
                        |translate}}</button>
                    <div class="alert alert-success mt-3" *ngIf="saveState.alertUi === 1">
                        {{'CONF_SAVED' | translate}}
                    </div>
                    <div class="alert alert-danger mt-3" *ngIf="saveState.alertUi === 2">
                        {{'ERROR' | translate}}
                    </div>
                </div>
            </form>
        </mat-expansion-panel>
        <!-- Login keys category -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span><span class="fa-light fa-fw fa-unlock"></span> {{'LOGIN_KEYS' |translate}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="loginKeyForm" (ngSubmit)="formSubmitSubject.next('submitLoginKeyForm')">
                <div class="form-group">
                    <mat-form-field>
                        <mat-select formControlName="loginKeys">
                            <mat-option *ngFor="let option of locationSettings.loginKeys.options" [value]="option">
                                {{option |translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="!defaultConfigChecked && !viewOnly">
                    <button type="submit" class="btn btn-primary"><span class="fa-light fa-fw fa-floppy-disk"></span>
                        {{'SAVE'
                        |translate}}</button>
                    <div class="alert alert-success mt-3" *ngIf="saveState.loginKeys === 1">
                        {{'CONF_SAVED' |translate}}
                    </div>
                    <div class="alert alert-danger mt-3" *ngIf="saveState.loginKeys === 2">
                        {{'ERROR' |translate}}
                    </div>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="mt-3" *ngIf="defaultConfigChecked && !viewOnly">
        <div class="alert alert-success mb-2" *ngIf="saveState.defaultConfigForms === 1">
            {{'CONF_SAVED' |translate}}
        </div>
        <div class="alert alert-danger mb-2" *ngIf="saveState.defaultConfigForms === 2">
            {{'ERROR' |translate}}
        </div>
        <button class="btn btn-primary" [disabled]="saving" (click)="submitDefaultConfigForms()">
            <span [ngClass]="saving ? 'fa-duotone fa-spinner-third fa-spin' : 'fa-light fa-fw fa-floppy-disk'"></span>
            {{ saving ? 'SAVING' : 'SAVE' | translate }}
        </button>
    </div>
</div>
