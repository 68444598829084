import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { GlobalVariables } from "../services/helper.service";
import {
  BehaviorSubject,
  concat,
  forkJoin,
  Observable,
  PartialObserver,
  Subscriber,
} from "rxjs";
import { environment } from "../../environments/environment";
import * as io from "socket.io-client";
import { ActiveCameraVisit } from "app/models/camerasupervision.model";

export class VideoServerUser {
  userId: string;
  companyId: string;
  organisationId: string; // company or location ID
  alertServerToken: string;
  lyraApiToken: string;

  static fromObject(obj: any): VideoServerUser | null {
    if (typeof obj !== "object") return null;
    if (typeof obj.userId !== "string") return null;
    if (typeof obj.companyId !== "string") return null;
    if (typeof obj.userowerid !== "string") return null;
    if (typeof obj.authToken !== "string") return null;
    if (typeof obj.everonApiToken !== "string") return null;

    return {
      userId: obj.userId,
      companyId: obj.companyId,
      organisationId: obj.userowerid,
      alertServerToken: obj.authToken,
      lyraApiToken: obj.everonApiToken,
    };
  }
}

@Injectable()
export class VideoServerService {
  constructor(
    private cookies: CookieService,
    private router: Router,
    private globals: GlobalVariables,
    backend: HttpBackend
  ) {
    this.http = new HttpClient(backend);
    this.onlineVideoObservable = new Observable((subscriber) => {
      this.onlineVideoSubscriber = subscriber;
    });
  }

  private onlineVideoObservable: Observable<boolean>;
  private onlineVideoSubscriber: Subscriber<boolean>;

  //
  // Observable for receiving online/offline status of the video server
  //
  get isConnected(): Observable<boolean> {
    return this.onlineVideoObservable;
  }

  private http: HttpClient;
  private baseUrl = environment.videoServerBaseUrl;
  private socket: any = null;
  private users: VideoServerUser[] = [];
  private activeVisitsSubjects: Map<
    string,
    BehaviorSubject<ActiveCameraVisit[]>
  > = new Map<string, BehaviorSubject<ActiveCameraVisit[]>>();
  private _combinedVisits$: BehaviorSubject<ActiveCameraVisit[]> =
    new BehaviorSubject([]);

  login(): Observable<any> {
    let url = `${this.baseUrl}/api/tokens/portal`;
    let options = {
      headers: new HttpHeaders({
        Authorization: this.cookies.get("session-token"),
      }),
    };
    return this.http.get(url, options);
  }

  getAllCameraVisits(minutes: string): Observable<any> {
    let url = `${this.baseUrl}/api/schedule?f=${minutes}`;
    let options = {
      headers: new HttpHeaders({
        Authorization: this.cookies.get("videoserver-session-token"),
      }),
    };
    return this.http.get(url, options);
  }

  getActiveCameraVisits(): Observable<any> {
    let url = `${this.baseUrl}/api/visits`;
    let options = {
      headers: new HttpHeaders({
        Authorization: this.cookies.get("videoserver-session-token"),
      }),
    };
    return this.http.get(url, options);
  }

  getAllCameraGroups(): Observable<any> {
    let url = `${this.baseUrl}/api/cameragroups`;
    let options = {
      headers: new HttpHeaders({
        Authorization: this.cookies.get("videoserver-session-token"),
      }),
    };
    return this.http.get(url, options);
  }

  saveNewVisit(groupId: string, data: any): Observable<any> {
    let url = `${this.baseUrl}/api/cameragroups/${groupId}/schedule`;
    let options = {
      headers: new HttpHeaders({
        Authorization: this.cookies.get("videoserver-session-token"),
      }),
    };
    return this.http.post(url, data, options);
  }

  deleteVisit(groupId: string, scheduleId: string): Observable<any> {
    let url = `${this.baseUrl}/api/cameragroups/${groupId}/schedule/${scheduleId}`;
    let options = {
      headers: new HttpHeaders({
        Authorization: this.cookies.get("videoserver-session-token"),
      }),
    };
    return this.http.delete(url, options);
  }

  trackVisits(users: VideoServerUser[]) {
    this.users = users;
    if (users.length === 0) return;
    if (this.socket !== null) {
      console.warn(
        "Connecting to video controller websocket while existing websocket exist (closing existing)"
      );
      this.socket.close();
    }
    this.socket = io(`${this.baseUrl}`);

    this.socket.on("connect", () => {
      console.log(`Listening video controller events from ${this.baseUrl}`);
      this.onlineVideoSubscriber?.next(true);
    });
    this.socket.on("disconnect", () => {
      console.log(
        `No connection to video controller events from ${this.baseUrl}`
      );
      this.onlineVideoSubscriber?.next(false);
    });

    for (const user of users) {
      const subject = new BehaviorSubject<ActiveCameraVisit[]>([]);
      this.activeVisitsSubjects.set(user.organisationId, subject);
      this.socket.on("connect", () => {
        console.log(
          `Retrieving visit state after connect: organisation ${user.organisationId}`
        );
        this.updateTrackedVisit(user);
      });
      this.socket.on(`schedule-state-change-${user.organisationId}`, (data) => {
        console.log(`Visit state changed: organisation ${user.organisationId}`);
        this.updateTrackedVisit(user);
      });
    }
  }

  untrackVisits() {
    if (this.socket === null) {
      console.warn(
        "Disconnecting from non-existing video server websocket (doing nothing)"
      );
      return;
    }
    this.socket.close();
    this.socket = null;
    this.users = [];
    this.activeVisitsSubjects = new Map<
      string,
      BehaviorSubject<ActiveCameraVisit[]>
    >();
    this._combinedVisits$.next([]);
  }

  getTrackingUsers(): VideoServerUser[] {
    return this.users;
  }

  getTrackedVisitsSubject(
    organisationId: string
  ): BehaviorSubject<ActiveCameraVisit[]> {
    return this.activeVisitsSubjects.get(organisationId);
  }

  get combinedVisits$(): Observable<ActiveCameraVisit[]> | null {
    return this._combinedVisits$;
  }

  get isTracking(): boolean {
    return !!this.socket && !!this.socket.id;
  }

  private updateTrackedVisit(user: VideoServerUser) {
    this.getActiveCameraVisitsOfUser(user).subscribe((res) => {
      //Add videoserver token into each visit object
      res = res.map((v, i) => ({ ...v, userToken: user.alertServerToken }));
      // update this organisation subject
      this.activeVisitsSubjects.get(user.organisationId)?.next(res);

      // ...and also the combined subject
      let combined: ActiveCameraVisit[] = [];
      this.activeVisitsSubjects.forEach((val, key) => {
        combined = combined.concat(val.value);
      });
      this._combinedVisits$.next(combined);
    });
  }

  private getActiveCameraVisitsOfUser(user: VideoServerUser): Observable<any> {
    let url = `${this.baseUrl}/api/visits`;
    let options = {
      headers: new HttpHeaders({
        "x-alertsrv-authtoken": user.alertServerToken,
        "x-alertsrv-sessionid": user.alertServerToken,
        "x-everon-companyid": user.companyId,
      }),
    };
    return this.http.get(url, options);
  }

  startVisit(visit: any): Observable<any> {
    let url = `${this.baseUrl}/api/visits/${visit.id}/started`;
    let options = {
      headers: new HttpHeaders({
        "x-alertsrv-authtoken": visit.token,
        "x-alertsrv-sessionid": visit.token,
        "x-everon-companyid": visit.companyId,
      }),
    };
    return this.http.put(url, null, options);
  }

  cancelStartedVisit(visit: any): Observable<any> {
    let url = `${this.baseUrl}/api/visits/${visit.id}/started`;
    let options = {
      headers: new HttpHeaders({
        "x-alertsrv-authtoken": visit.token,
        "x-alertsrv-sessionid": visit.token,
        "x-everon-companyid": visit.companyId,
      }),
    };
    return this.http.delete(url, options);
  }

  completeVisit(visit: any): Observable<any> {
    let url = `${this.baseUrl}/api/visits/${visit.id}/completed`;
    let options = {
      headers: new HttpHeaders({
        "x-alertsrv-authtoken": visit.token,
        "x-alertsrv-sessionid": visit.token,
        "x-everon-companyid": visit.companyId,
      }),
    };
    return this.http.put(url, null, options);
  }

  snoozeVisit(visit: any): Observable<any> {
    let url = `${this.baseUrl}/api/visits/${visit.id}/snooze`;
    let options = {
      headers: new HttpHeaders({
        "x-alertsrv-authtoken": visit.token,
        "x-alertsrv-sessionid": visit.token,
        "x-everon-companyid": visit.companyId,
      })
    };
    return this.http.put(url, null, options);
  }

}
