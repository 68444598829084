<div class="container">

    <div class="row">
        <div class="col-12">
            <h6 *ngIf="!fallbackRecipients" class="text-center routeEditGroupTitle mb-2">{{'RECIPIENTS' | translate}}
            </h6>
            <h6 *ngIf="fallbackRecipients" class="text-center routeEditGroupTitle mb-2">{{'FALLBACK_RECIPIENTS'| translate}}</h6>
        </div>
    </div>

    <!-- Available recipients -->
    <div class="row justify-content-center">
        <div class="col-lg-5 col-md-12 white-bg">
            <div class="card card-borders p-2">
                <h6 class="text-center recipientGroupTitle">{{'AVAILABLE_RECIPIENTS' | translate }}</h6>

                <mat-list>
                    <ng-container *ngFor="let group of availableRecipientGroups | keyvalue">
                        <mat-list-item *ngIf="group.value.availableRecipients.length > 0" class="mb-3 location-item">
                            <div class="recipient-row">
                                <div>
                                    <span *ngIf="group.value.locationName" class="fa-fw mr-2 fa-solid fa-house recipientGroupTitle"></span>
                                    <span class="recipientGroupTitle">
                                        {{group.value.locationName}}
                                        {{group.value.translationSuffix | translate }}
                                    </span>
                                </div>

                                <mat-icon
                                  class="add-recipient-button"
                                  (click)="addRecipientsByTypeAndLocationId(
                                    group.value.availableRecipients[0].locationId,
                                    group.value.availableRecipients[0].type,
                                    group.key
                                  )"
                                >
                                    add_circle
                                </mat-icon>
                            </div>
                        </mat-list-item>

                        <mat-list-item *ngFor="let recipient of group.value.availableRecipients" class="mb-3">
                            <div class="recipient-row">
                                <div>
                                    <div [ngClass]="{'selected-recipient-opacity' : recipient.selected}">
                                        <span [ngClass]="recipient.type | recipientTypeIcon"></span>
                                        <span class="recipient-name">{{recipient.name}} ({{recipient.id}})</span>
                                    </div>

                                    <div *ngIf="recipient.type === 'USER'" [ngClass]="{'selected-recipient-opacity' : recipient.selected}">
                                        <span class="fa-light fa-phone fa-fw"></span>
                                        <span> {{recipient?.gsmNumber || '-'}}</span>
                                    </div>
                                </div>

                                <mat-icon class="add-recipient-button"
                                    [ngClass]="{'selected-recipient-opacity' : recipient.selected}"
                                    (click)="addRecipient(recipient); scrollToBottom('selected-recipients');">add_circle
                                </mat-icon>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                </mat-list>
            </div>
        </div>

        <!-- Selected recipients -->
        <div class="col-lg-5 col-md-12 white-bg">
            <div class="card card-borders p-2">
                <h6 class="text-center recipientGroupTitle">{{ 'SELECTED_RECIPIENTS' | translate }}</h6>

                <mat-list dense="true" id="selected-recipients">
                    <div cdkDropList (cdkDropListDropped)="dragAndDrop($event)">
                        <mat-list-item
                          class="mb-3 selected-recipient-container"
                          [@addRecipientAnimation]="addedAnimation"
                          *ngFor="let recipient of selectedRecipientsList; index as idx" cdkDrag
                        >
                            <div class="recipient-row" matListItemTitle>
                                <div class="d-flex flex-column">
                                    <div>
                                        <span [ngClass]="recipient.type | recipientTypeIcon"></span>
                                        <span class="recipient-name">{{recipient.name}} ({{recipient.id}})</span>
                                    </div>

                                    <div *ngIf="recipient.type === 'USER'" class="text-truncate">
                                        <span class="fa-light fa-phone fa-fw"></span>
                                        <span> {{recipient?.gsmNumber || '-'}}</span>
                                    </div>
                                </div>

                                <div *cdkDragPreview class="drag-preview">
                                    <span [ngClass]="recipient.type | recipientTypeIcon"></span>
                                    {{recipient.name}} ({{recipient.id}})
                                    {{idx}}
                                </div>

                                <div class="drag-placeholder" *cdkDragPlaceholder></div>

                                <mat-icon class="remove-recipient-button" (click)="removeRecipient(idx)">
                                    remove_circle
                                </mat-icon>
                            </div>
                        </mat-list-item>
                    </div>
                </mat-list>
            </div>
        </div>
    </div>
</div>
