
/**
 * User model.
 * Note: this model may miss some properties
 */
export interface IUser {
  userId: string;
  username: string;
  displayName: string;
  firstName: string;
  familyName: string;
  email: string;
  role: string;
  roleLevel: UserRoleLevel;
  server: string;
  customerId: string;
  locationId: string;
  timezone: string;
  utcOffset: number;
}

/**
 * User role levels
 */
export enum UserRoleLevel
{
    ReadOnly = 0,
    EMAOnly = 10,
    AnnaPerennaMainUser = 40,
    Editor = 50,
    LandingPageUser = 95,
    MainUser = 100,
    CustomerAdmin = 101,
    ControlRoomUser = 120,
    ServiceUser = 150,
    SystemAdmin = 200
}
