import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { CrSyncService } from '../../services/crsync.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment-timezone';
import { MomentPipe } from '../../pipes/pipes';
import { FormsModule } from '@angular/forms';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [NgFor, NgClass, NgIf, FormsModule, TranslateModule, MomentPipe]
})

export class LoginComponent implements OnInit {

  constructor(
    private api: ApiService,
    private crSync: CrSyncService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private cookies: CookieService
  ) { };

  loginUsername: string;
  loginPassword: string;
  loginError: string;
  loginBtnText: string;
  loginStatus: boolean = false;
  languages: any;
  activeLanguage: string;
  notification: any;
  returnUrl: string;
  twoFactorRequired: boolean = false;
  twoFactorCode: number;
  passwordExpired: boolean = false;
  passwordExpired2FA: boolean = false;
  expiredOldPassword: string;
  expiredNewPassword: string;
  expiredNewPasswordConfirm: string;
  twoFactorCodeInvalid: boolean = false;
  passwordChangeError: string;

  onLogin(isValid: boolean): void {
    if (isValid) {
      this.loginError = "";
      let data = {
        username: this.loginUsername,
        password: this.loginPassword
      }
      //Send login request to server through API service
      this.api.login(data)
        .subscribe((res => {
          //2FA code is required for login
          if (res.status === 'LoginRequiresAuthenticator') {
            this.twoFactorRequired = true;
            this.loginStatus = false;
            return;
          }
          //Password has expired
          if (res.status === 'PasswordExpired') {
            this.passwordExpired = true;
            this.loginStatus = false;
            return;
          }
          this.parseLoginResponse(res);
        }), err => {
          //If server returned error display it in the login page.
          this.loginStatus = false;
          switch (err.status) {
            case 401:
              this.translate.get('LOGIN_ERROR_WRONG').subscribe(t => this.loginError = t);
              break;
            case 403:
              this.isAccountLocked(err);
              break;
            default:
              this.translate.get('LOGIN_ERROR_GENERAL').subscribe(t => this.loginError = t);
              break;
          }
        });
    } else {
      //If login form was not valid set the login status to false. This resets the loading icon for the login button.
      this.loginStatus = false;
    }
  }

  onTwoFactorLogin(): void {
    let data: any = {
      username: this.loginUsername,
      password: this.loginPassword,
      code: this.twoFactorCode
    }
    if (this.twoFactorCodeInvalid) {
      data.newPassword = this.expiredNewPasswordConfirm;
    }
    this.api.login(data).subscribe(res => {
      //Password has expired
      if (res.status === 'PasswordExpired') {
        this.passwordExpired = true;
        this.passwordExpired2FA = true;
        this.twoFactorRequired = false;
        this.loginStatus = false;
        return;
      }
      this.parseLoginResponse(res);
    }, err => {
      //If server returned error display it in the login page.
      this.loginStatus = false;
      this.loginError = this.translate.instant(err.error.status);
    })
  }

  onPasswordExpire(): void {
    this.passwordChangeError = "";
    if (this.expiredNewPassword != this.expiredNewPasswordConfirm) {
      this.passwordChangeError = "PASSWORDS_DONT_MATCH";
      return;
    }
    let data: any = {
      username: this.loginUsername,
      password: this.expiredOldPassword,
      newPassword: this.expiredNewPasswordConfirm
    }
    if (this.passwordExpired2FA) {
      data.code = this.twoFactorCode
    }
    this.api.login(data).subscribe(res => {
      this.parseLoginResponse(res);
    }, err => {
      //2FA code was invalid, ask it again
      if (err.error.status === 'InvalidTwoFactorPin') {
        this.twoFactorCode = null;
        this.passwordExpired = false;
        this.passwordExpired2FA = false;
        this.twoFactorCodeInvalid = true;
        this.twoFactorRequired = true;
        this.loginStatus = false;
        return;
      }
      this.passwordChangeError = "LOGIN_ERROR_WRONG";
    })
  }

  parseLoginResponse(res: any): void {
    //Set the token received from the server to the cookie.
    this.cookies.set("session-token", "Bearer " + res.token, undefined, "/", undefined, false, "Lax");
    //Set logged in user data into local storage for later use.
    localStorage.setItem("user", JSON.stringify(res));
    //If admin tries to open normal dashboard redirect to admin dashboard
    if (res.role === "SystemAdmin") {
      this.router.navigate(['dashboard/admin']);
    } else if (res.role === "ControlRoomUser") {
      this.crSync.connect(this.loginUsername, this.loginPassword);
      this.router.navigate(['cr']);
    } else {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  //Function which is called whenever user changes to language
  setActiveLanguage(language: any): void {
    //Set selected language as the active language
    this.activeLanguage = language.name
    //Store the active language to local storage so it can be used in other pages also
    localStorage.setItem("language", this.activeLanguage)
    //Set selected language as the used language for translate module
    this.translate.use(this.activeLanguage)
    //Set selected language as the used language for moment module (used to format dates and times)
    if (this.activeLanguage == 'en') {
      moment.locale('en-gb');
    }
    else {
      moment.locale(this.activeLanguage);
    }
    this.api.getNotifications(this.activeLanguage, 1, true)
      .subscribe(res => {
        if (res != null) {
          this.notification = res[0];
        } else {
          this.notification = null;
        }
      })
  }

  //Function fired when page loads the first time
  ngOnInit(): void {

    //Initialize all languages so they can be showed in the page
    this.languages = [
      {
        imgUrl: 'assets/img/flags/fi.png',
        name: 'fi'
      },
      {
        imgUrl: 'assets/img/flags/sv.png',
        name: 'sv'
      },
      {
        imgUrl: 'assets/img/flags/uk.png',
        name: 'en'
      },
      {
        imgUrl: 'assets/img/flags/ee.png',
        name: 'ee'
      }
      // {
      //   imgUrl: 'assets/img/flags/nb.png',
      //   name: 'nb'
      // }
    ];
    //Get the active language from the local storage
    this.activeLanguage = localStorage.getItem("language")
    //If local storage had language saved use it in translate and moment modules
    if (this.activeLanguage && this.activeLanguage !== "null") {
      this.translate.use(this.activeLanguage)
      moment.locale(this.activeLanguage);
    } else {
      //If local storage didn't have language saved use the browser language or the first language in the list and set it to local storage and modules.
      //List of supported languages
      let supportedLang: string[] = ["fi", "sv", "en", "de"];
      //Get first 2 chars from the browser language. Result can be ie. 'en-US' so use only 'en'.
      let browserLang = navigator.language.substring(0, 2);
      //Check that the browser language is supported
      if (browserLang && supportedLang.indexOf(browserLang) !== -1) {
        this.activeLanguage = browserLang;
      } else {
        //If browser language is not supported use 'en'
        this.activeLanguage = this.languages[2].name;
      }
      localStorage.setItem("language", this.activeLanguage)
      this.translate.use(this.activeLanguage)
      moment.locale(this.activeLanguage);
    }
    this.api.getNotifications(this.activeLanguage, 1, true)
      .subscribe(res => {
        if (res != null) {
          this.notification = res[0];
        } else {
          this.notification = null;
        }
      })

    this.translate.get('SIGN_IN').subscribe(t => this.loginBtnText = t);
    //Get return url from route parameters and default to /dashboard
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';

    //Show reason for automatic logout
    let logoutReason: string = this.route.snapshot.queryParams['reason'];
    if (logoutReason) {
      this.translate.get(logoutReason.toUpperCase()).subscribe(t => this.loginError = t);
    }
  }

  private isAccountLocked(response: HttpErrorResponse): void {
    if (response?.error?.status === "AccountLocked") {
      this.loginError = this.translate.instant('LOGIN_ERROR_ACCOUNT_LOCKED');
    }
  }
}
