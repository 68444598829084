<div class="card">
    <div class="card-header-white rounded-corners cursorPointer" data-testid="header" (click)="loadActiveAlerts()">
        <h6 style="position: relative; top: 25%">
            {{ "ACTIVE_ALARMS" | translate }}
        </h6>
        <span
          class="fa-solid fa-xl float-right caretIcon"
          [ngClass]="panelOpen ? 'fa-chevron-up' : 'fa-chevron-down'">
        </span>
    </div>
    <div class="card-body-white" data-testid="body" *ngIf="panelOpen" [@animationCollapseLeave]>
        <div *ngIf="!activeAlerts || !activeAlertsLoaded">
            <img
              *ngIf="!activeAlertsLoaded"
              class="fadeLoader centerLoader logoSmall"
              src="assets/img/loading_logo.svg"
            />
            <span *ngIf="activeAlertsLoaded">{{ "NO_ALARMS" | translate }}</span>
        </div>

        <div class="activeAlertsTable" *ngIf="activeAlerts" [@animationCollapse]>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th style="width: 1%"></th>
                        <th>
                            {{ "ALARM_REGISTERED" | translate }}
                        </th>
                        <th>{{ "DEVICE" | translate }}</th>
                        <th>{{ "ALARM_TYPE" | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let alert of activeAlerts">
                        <td>
                          <span class="fa-light fa-fw" [ngClass]="alert | deliveryClass"></span>
                        </td>
                        <td>{{ alert.receiveTime | moment : "l LTS" }}</td>
                        <td>{{ alert.deviceName ? alert.deviceName : alert.deviceId }}</td>
                        <td>
                          {{
                            getActivationName(
                              alert.activationGroup,
                              alert.activationNode,
                              alert.deviceType,
                              alert.customActivationName,
                              alert.connTimeLimit
                            )
                          }}
                        </td>
                        <td>
                            <!-- Read only user is not allowed cancel an active alert -->
                            <button *ngIf="!alert.cancelled && !alert.error && user?.roleLevel > 0" data-testid="cancel-button" class="btn btn-outline-dark danger-text fullWidth" (click)="cancelAlert(alert)">
                              <span class="fa-light fa-circle-stop"></span>
                              {{ "CANCEL_DELIVERY" | translate}}
                            </button>

                            <span *ngIf="alert.cancelled" class="danger-text">{{ "DELIVERY_CANCELLED" | translate }}</span>
                            <span *ngIf="alert.error"class="danger-text">{{ "ERROR" | translate }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
