import { AlarmRouteModel, DeliveryType, Route, ServiceTimeValidity, ValidationModel, } from '../../../models/alarmroutes.model';
import { CallCenter } from '../../../models/callcenter.model';
import { AlarmRouteBasicInformationComponent } from './alarm-route-basic-information/alarm-route-basic-information.component';
import { ApiService } from '../../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ValidationDialogContentComponent } from './validation-dialog-content/validation-dialog-content.component';
import { Device } from '../../../models/device.model';
import { StaffModel } from '../../../models/staff.model';
import { ReceiverTeam } from '../../../models/receiver-team';
import { TranslateModule } from '@ngx-translate/core';
import { AlarmRouteAlarmIndicationGroupsComponent } from './alarm-route-alarm-indication-groups/alarm-route-alarm-indication-groups.component';
import { AlarmRouteFallbackRecipientsComponent } from './alarm-route-fallback-recipients/alarm-route-fallback-recipients.component';
import { AlarmRouteSchedulesComponent } from './alarm-route-schedules/alarm-route-schedules.component';
import { AlarmRouteRecipientsComponent } from './alarm-route-recipients/alarm-route-recipients.component';
import { AlarmRouteDevicesComponent } from './alarm-route-devices/alarm-route-devices.component';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild,
  ViewChildren
} from '@angular/core';

type Step = "basic-info" | "devices" | "recipients" | "schedule" | "fallback-recipients" | "alarm-indication-group";

@Component({
  selector: 'alarm-route-edit',
  templateUrl: './alarm-route-edit.component.html',
  styleUrls: ['./alarm-route-edit.component.css'],
  standalone: true,
  imports: [CommonModule, MatStepperModule, MatIconModule, AlarmRouteBasicInformationComponent, AlarmRouteDevicesComponent, AlarmRouteRecipientsComponent, AlarmRouteSchedulesComponent, AlarmRouteFallbackRecipientsComponent, AlarmRouteAlarmIndicationGroupsComponent, TranslateModule]
})
export class AlarmRouteEditComponent implements OnInit {

  constructor(private cd: ChangeDetectorRef, private api: ApiService, private dialog: MatDialog) { }

  private _deviceMap: Map<string, Device> = new Map<string, Device>();
  private _selectedStep: Step;

  @Output() routeCreated = new EventEmitter();
  @Output() routeEdited = new EventEmitter<Route>();
  @ViewChild(AlarmRouteBasicInformationComponent, { static: true }) informationFormGroup: AlarmRouteBasicInformationComponent;
  @ViewChildren("stepId") stepIds!: QueryList<ElementRef>;

  @Input('route') route: Route;
  @Input('callCenters') callCenters: CallCenter[];
  @Output() closeEditForm = new EventEmitter<void>();
  @Input('newRoute') newRoute: boolean;
  @Input('startTab') startTab: number = 0;

  @Input('alarmRouteLocations') alarmRouteLocations: AlarmRouteModel[];

  tempRoute: Route;
  validationModel: ValidationModel;
  customerInformation: any;
  rawDeviceList: Device[] = [];
  allStaff: StaffModel[] = [];
  allReceiverTeams: ReceiverTeam[] = [];
  initialized: boolean = false;

  get selectedStep(): Step {
    if (!this._selectedStep) {
      const stepId = this.stepIds.get(this.startTab)?.nativeElement?.id as Step;
      if (stepId) setTimeout(() => this._selectedStep = stepId); // Delay setting selectedStep to avoid ExpressionChangedAfterItHasBeenCheckedError
    }
    return this._selectedStep;
  }

  get deviceMap(): Map<string, Device> {
    return this._deviceMap;
  }

  set deviceMap(devices: Device[]) {
    this._deviceMap = new Map<string, Device>(devices?.map(device => [device.id, device]));
  }

  get informationForm() {
    return this.informationFormGroup ? this.informationFormGroup.informationForm : null;
  }

  onStepChange(step: StepperSelectionEvent): void {
    const stepIndex = step.selectedIndex;
    this._selectedStep = this.stepIds.get(stepIndex)?.nativeElement?.id as Step;
  }

  selectTabIndex(): void {
    let availableTabs: number;

    if (this.tempRoute.fallBackType === DeliveryType.None) {
      availableTabs = 5;
    }

    if (this.startTab === 2 && this.tempRoute.defaultRoute) {
      this.startTab = 1;
    }

    if (this.startTab > availableTabs - 1) {
      this.startTab = 0;
    }
  }

  openValidationDialog(): void {
    const dialogRef = this.dialog.open(ValidationDialogContentComponent, {
      data: { validationModel: this.validationModel, deviceMap: this.deviceMap },
    });

    dialogRef.afterClosed().subscribe(saveAsIncomplete => {
      if (saveAsIncomplete) {
        this.tempRoute.enabled = false;
        this.validateRoute(true);
      }
    });
  }

  // This method is the "final" call for "cleaning" up the route.
  cleanUpRoute(): void {
    if (this.tempRoute.defaultRoute) {
      this.tempRoute.devices = null;
      this.tempRoute.serviceTimes = null;
      this.tempRoute.serviceTimeValidity = ServiceTimeValidity.Always;
    }

    if (this.tempRoute.fallBackType === DeliveryType.None) {
      this.tempRoute.fallBackRecipients = [];
    }

    if (!this.tempRoute.alarmIndicationGroupEnabled) {
      this.tempRoute.alarmIndicationGroups = [];
    }

    if (!this.tempRoute.deliveryType.includes('CALL')) {
      this.tempRoute.callLoopFailType = "NONE";
    }
  }

  validateModifiedRoute(inComplete: boolean): void {
    this.api.updateAlarmRoute(this.tempRoute).subscribe(res => {
      this.validationModel = res;
      if (this.validationModel && !inComplete) {
        this.openValidationDialog();
      } else {
        this.routeEditedEmitter();
        this.closeForm()
      }
    }, err => {
      this.validationModel = err.error;
      if (!inComplete) {
        this.openValidationDialog();
      }
    });
  }

  validateNewRoute(inComplete: boolean): void {
    this.api.addAlarmRoute(this.tempRoute).subscribe(res => {
      this.validationModel = res;
      if (this.validationModel && !inComplete) {
        this.openValidationDialog();
      } else {
        this.routeCreatedEmitter();
        this.closeForm()
      }
    }, err => {
      this.validationModel = err.error;
      if (!inComplete) {
        this.openValidationDialog();
      }
    });
  }

  validateRoute(inComplete: boolean): void {
    this.cleanUpRoute();
    if (this.newRoute) {
      this.validateNewRoute(inComplete);
    } else {
      this.validateModifiedRoute(inComplete);
    }
  }

  routeCreatedEmitter(): void {
    this.routeCreated.emit();
  }

  routeEditedEmitter(): void {
    this.routeEdited.emit(this.tempRoute);
  }

  closeForm(): void {
    this.closeEditForm.emit();
    this.newRoute = !this.newRoute;
  }

  // By recreating object (changing reference), we trigger onChange on child components
  onLocationChange(): void {
    this.tempRoute = JSON.parse(JSON.stringify(this.tempRoute));
  }

  async getDevices(): Promise<void> {
    await new Promise<void>(resolve => this.api.getDevicesWithBasicInfo()
      .subscribe(res => {
        this.deviceMap = this.rawDeviceList = res;
        resolve();
      }));
  }

  async getDeviceTranslations(): Promise<void> {
    let devTypeList = localStorage.getItem('devType');
    //If list is not in storage download it from the server and set it to local storage
    if (!devTypeList) {
      await new Promise<void>(resolve => this.api.getDeviceTranslations(localStorage.getItem('language'))
        .subscribe(res => {
          localStorage.setItem('devType', JSON.stringify(res));
          devTypeList = res;
          resolve();
        }));
    } else {
      await Promise.resolve();
    }
  }

  async getAllStaff(): Promise<void> {
    if (this.allStaff) {
      await Promise.resolve();
    }
    return new Promise<void>(resolve => {
      this.api.getStaffList().subscribe(res => {
        this.allStaff = res.sort((a, b) => a.fullName.localeCompare(b.fullName));
        resolve();
      });
    });
  }

  async getAllReceiverTeams(): Promise<void> {
    if (this.allReceiverTeams) {
      await Promise.resolve();
    }
    return new Promise<void>(resolve => {
      this.api.getReceiverTeams().subscribe(res => {
        this.allReceiverTeams = res;
        resolve();
      });
    });
  }

  async getCustomerInformation(): Promise<void> {
    return new Promise<void>(resolve => {
      this.api.getCustomerInformation().subscribe(res => {
        this.customerInformation = res;
        resolve();
      });
    });
  }

  async fetchInitialData(): Promise<void> {
    await Promise.allSettled([this.getDevices(), this.getDeviceTranslations(), this.getAllStaff(), this.getAllReceiverTeams(), this.getCustomerInformation()]);
  }

  ngOnInit() {
    this.fetchInitialData().then(_ => {
      this.tempRoute = JSON.parse(JSON.stringify(this.route));

      // Route should be enabled as default, if route is saved as incomplete then enabled is false
      this.tempRoute.enabled = true;
      this.selectTabIndex();

      this.initialized = true;
    });
  }
}



