import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { HelperService } from '../../services/helper.service';
import { AnimationCollapse, AnimationCollapseLeave } from '../../animations';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { MomentPipe } from '../../pipes/pipes';
import { TrainingVideosComponent } from './training-videos/training-videos.component';
import { DeviceStatusComponent } from './devicestatus/devicestatus.component';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication/two-factor-authentication.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActiveAlertsComponent } from './active-alerts/active-alerts.component';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    AnimationCollapse,
    AnimationCollapseLeave
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, MatSlideToggleModule, TwoFactorAuthenticationComponent, DeviceStatusComponent, ActiveAlertsComponent, TrainingVideosComponent, TranslateModule, MomentPipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
  constructor(
    private api: ApiService,
    private helper: HelperService,
  ) { };

  user: any;
  enabledFeatures: string[];
  allFeatures: string[];
  tempEnabledFeatures: string[];
  originalCustomerInformation: any;
  featuresChanged: boolean = false;
  customerInformation: any;
  notifications: any;
  onlineStatus: any;
  onlineStatusBases: any;
  showBaseDevicesOnly: boolean = false;
  activationArray: any;
  customerEdit: boolean;
  lang: string;

  formSubmitSubject = new Subject();

  getFeatureClass(feature: string) {
    switch (feature) {
      case 'DeviceMovement':
        return 'fa-certificate';
      case 'EveronFloorMapService':
        return 'fa-location-dot';
      case 'EveronMobileApp':
      case 'EmaPhoneWhitelist':
        return 'fa-mobile';
      case 'SmartInstall':
        return 'fa-circle-plus';
      case 'TemperatureSmokeDetector':
        return 'fa-fire';
      case 'AnnaPerenna':
        return 'fa-camera';
      case 'Joliv':
        return 'fa-diamond-turn-right';
      case 'EnhancedSecurity':
        return 'fa-shield-halved';
      case 'StrongAuthentication':
        return 'fa-user-shield';
      case 'OnOn':
        return 'fa-camera-security';
      case 'DeviceRegistry':
        return 'fa-gears';
      case 'CameraSupervision':
        return 'fa-camera-cctv';
      case 'SCAIP':
        return 'fa-share-all';
      case 'DigitalKey':
        return 'fa-key';
      case 'IotComms':
      case 'EMAAC':
        return 'fa-user-headset';
      case 'EmaAutomaticLogout':
        return 'fa-arrow-right-from-bracket'
      case 'AnonymizedCameraSupervision':
        return 'fa-solid fa-video-slash ml-4';
      case 'SnoozableVisits':
        return 'fa-solid fa-stopwatch ml-4';
      default:
        break;
    }
  }

  isFeatureEnabled(feature: string, useCache: boolean = true): boolean {
    let features: any;
    if (useCache) {
      features = JSON.parse(JSON.stringify(this.enabledFeatures))
    } else {
      features = JSON.parse(localStorage.getItem('features'));
    }
    // Check that customer has features enabled and if current feature is in that list
    if (features && features.indexOf(feature) !== -1) {
      return true;
    }
    return false;
  }

  enableFeature(feature: string): void {
    let isEnabled = false;

    // Check that customer has features enabled and if the selected feature is in that list. If list is not found create it.
    if (this.enabledFeatures) {
      isEnabled = this.enabledFeatures.indexOf(feature) !== -1;
    } else {
      this.enabledFeatures = [];
    }

    // If feature cannot be enabled return
    if (!this.canEnableFeature(feature)) {
      return;
    }

    // Tell form validator that features has been changed
    this.featuresChanged = true;

    // Copy enabled features to other list in case of form modify cancel
    if (!this.tempEnabledFeatures) {
      this.tempEnabledFeatures = this.enabledFeatures.slice();
    }

    // If feature is already enabled remove it from enabled list. Otherwise add it to the list
    isEnabled ? this.removeFeature(feature) : this.addFeature(feature);
  }

  canEnableFeature(feature: string): boolean {
    // Main feature must be enabled before sub feature can be enabled
    if ((feature === 'AnonymizedCameraSupervision' || feature === 'SnoozableVisits') && this.enabledFeatures?.indexOf('CameraSupervision') === -1) {
      return false;
    }
    return true;
  }

  removeFeature(feature: string): void {
    let featuresToRemove = [];

    // Check if feature has sub features that need to be removed as well
    switch (feature) {
      case 'CameraSupervision':
        featuresToRemove = ["CameraSupervision", "AnonymizedCameraSupervision", "SnoozableVisits"];
        break;
      default:
        featuresToRemove = [feature];
        break;
    }

    // Remove the features from the enabled features list
    for (const feature of featuresToRemove) {
      const index = this.enabledFeatures.indexOf(feature);
      if (index !== -1) {
        this.enabledFeatures.splice(index, 1);
      }
    }
  }

  addFeature(feature: string): void {
    this.enabledFeatures.push(feature);
    this.sortSubFeatures(this.enabledFeatures);
  }

  // Note: this function both modifies the original array and returns it for flexibility purposes
  sortSubFeatures(featureList: string[]): string[] {
    const map: Map<string, string[]> = new Map();
    map.set('CameraSupervision', ['AnonymizedCameraSupervision', 'SnoozableVisits']);

    map.forEach((value, key) => {
      this.moveFeatures(featureList, key, value);
    });

    return featureList;
  }

  moveFeatures(featureList: string[], targetFeature: string, featuresToMoveAfterTarget: string[]): void {
    // Check if the target feature exists in the array
    const targetIndex = featureList?.indexOf(targetFeature) ?? -1;

    // If the target feature exists, move the features after the target feature
    if (targetIndex !== -1) {
      // Filter out features to move that are not in the original featureList
      const validFeaturesToMove = featuresToMoveAfterTarget?.filter(feature => featureList.includes(feature));

      // Remove the features to move from their current positions, if found
      for (const feature of validFeaturesToMove) {
        const featureIndex = featureList.indexOf(feature);
        if (featureIndex !== -1) {
          featureList.splice(featureIndex, 1);
        }
      }

      // Calculate the index where features should be moved after the target feature
      const insertIndex = featureList.indexOf(targetFeature) + 1;

      // Insert the valid features to move after the target feature
      featureList.splice(insertIndex, 0, ...validFeaturesToMove);
    }
  }

  startCustomerEdit(): void {
    this.customerEdit = true;
    // Set original customer information into memory in case of undo. This needs to be done with JSON parse and stringify because by default JS just copies the reference of the variable
    this.originalCustomerInformation = JSON.parse(JSON.stringify(this.customerInformation));
  }

  cancelCustomerEdit(): void {
    this.customerEdit = false;
    this.featuresChanged = false;
    // Get the original customer information from the memory and set it as active
    this.customerInformation = JSON.parse(JSON.stringify(this.originalCustomerInformation));
    if (this.tempEnabledFeatures) {
      this.enabledFeatures = this.tempEnabledFeatures.slice();
      this.tempEnabledFeatures = null
    }
  }

  submitCustomerEdit(): void {
    this.api.submitCustomerInformation(this.customerInformation)
      .subscribe(res => {
        this.customerEdit = false;
        localStorage.setItem('customerInfo', JSON.stringify(this.customerInformation));
      });
    // Submit new features only if they have been changed
    if (this.featuresChanged) {
      this.api.submitFeatures(this.enabledFeatures)
        .subscribe(res => {
          localStorage.setItem('features', JSON.stringify(this.enabledFeatures));
          this.customerEdit = false;
        }, err => {
          console.log(err);
        })
    }

  }

  getOnlineStatus(basesOnly: boolean): void {
    if (basesOnly) {
      this.api.getOnlineStatusBases().subscribe((response: any) => {
        this.onlineStatus = response;
      });
    } else {
      this.api.getOnlineStatus(false)
        .subscribe((response: any) => {
          this.onlineStatus = response;
        });
    }
  }

  ngOnInit() {
    this.lang = localStorage.getItem('language');
    this.user = JSON.parse(localStorage.getItem('user'));

    this.api.getNotifications(this.lang, 7, false)
      .subscribe((response: any) => {
        this.notifications = response;
      });

    this.getOnlineStatus(false);

    this.api.getFeatures(true)
      .subscribe((response: any) => {
        localStorage.setItem('features', JSON.stringify(response));
        this.enabledFeatures = this.sortSubFeatures(response);
      });

    this.api.getFeatures(false)
      .subscribe(res => {
        this.allFeatures = this.sortSubFeatures(res);
      });

    this.api.getCustomerInformation()
      .subscribe((response: any) => {
        localStorage.setItem('customerInfo', JSON.stringify(response));
        this.customerInformation = response;
      })

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject.pipe(throttleTime(3000))
      .subscribe(() => {
        this.submitCustomerEdit();
      });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}


