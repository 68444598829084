<div class="card">
    <div class="card-header card-header-dark d-flex">
        <span class="mr-2">{{'STARTED_ALERTS' |translate}}</span>
        <app-notification-badge [count]="alerts.length" [warningStatus]="false"
            style="margin-top: 3px;"></app-notification-badge>
    </div>
    <div class="card-body card-body-dark" cdkDropList id="startedAlertList" [cdkDropListData]="alerts"
         [cdkDropListConnectedTo]="['incomingAlertList', 'forwardedAlertList']"
         (cdkDropListDropped)="crService.drop($event)">
        <div *ngFor="let alert of alerts" cdkDrag [cdkDragDisabled]="!alert.allowTransition">
            <!--Alert card-->
            <div class="alertCard">
                <div class="alertCardHeader"
                     [ngClass]="{'alertCardHeaderWithDrag': alert.allowTransition, 'alertCardTransitional': alert.transitional}"
                     cdkDragHandle>
                    <div class="row">
                        <div class="col-12 clearfix">
                            <div class="float-left mr-3">
                                <img src="{{ alert | pickUpAlertIcon }}" alt="noIMG">
                            </div>

                            <div class="alarmCardHeaderInfo">
                                <div class="mr-3">
                                    <div class="alarmSourceInfo">
                                        <p class="alertHeader">
                                            <strong class="text-nowrap">{{alert.header}} ({{alert.productId}})</strong>
                                        </p>

                                        <div
                                             class="cursorPointer ml-2"
                                             (click)="crSync.resetAlertIncrement(alert.userId, alert.alertId)"
                                             *ngIf="alert.increment > 0"
                                             matTooltip="{{'INCREMENT_INFO' |translate}} {{'CLICK_TO_RESET' |translate}}"
                                             [matTooltipPosition]="'above'">
                                            <span class="badge badge-pill badge-danger">{{'+' + alert.increment}}</span>
                                        </div>
                                    </div>

                                    <p class="alertHeader"> {{ alert | pickUpActivationName }}</p>

                                    <small>{{ alert.time | calculateAlertTime }}</small>
                                </div>

                                <div class="cancelResBtnContainer">
                                    <button class="btn btn-outline-primary cursorPointerHover float-right" style="text-wrap: nowrap;" (click)="cancelReservation(alert)">
                                        <span class="fa-light fa-fw fa-reply"></span>&nbsp;&nbsp;{{'CANCEL_RESERVATION' |translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alertCardBody">
                    <div class="mt-3">
                        <p class="mt-2">
                            <span class="fa-light fa-lg fa-fw fa-location-dot"></span>&nbsp;&nbsp;{{alert.address}}
                        </p>
                    </div>
                    <div class="mt-3" *ngIf="alert.place">
                        <p class="mt-2">
                            <span class="fa-light fa-lg fa-fw fa-microchip"></span>&nbsp;&nbsp;{{alert.place}}
                        </p>
                    </div>
                    <div class="mt-3">
                        <p [innerHTML]="alert.body | telephonify | newLineToBreak"></p>
                    </div>
                    <app-mark-alarm-reason [alert]="alert"></app-mark-alarm-reason>
                    <app-add-note [alert]="alert"></app-add-note>

                    <div class="mt-3">
                        <button class="btn btn-primary alertCardButton mb-3"
                                *ngIf="alert.baseMISDN"
                                data-testid="call-patient-link"
                                (click)="callPatient(alert.baseMISDN)">
                            <span class="fa-light fa-fw fa-phone"></span>
                            &nbsp;{{'CALL_PATIENT' |translate}}
                        </button>
                    </div>
                    <div class="mt-2 d-flex">

                        <button class="btn btn-outline-primary ml-1 mr-5 w-50"
                                (click)="completeAlert(alert)"
                                [disabled]="!(fireAlreadyAddedPipe | async | alreadyAdded : alert.alertId)">
                            <span class="fa-light fa-fw fa-box-archive"></span>
                            &nbsp;&nbsp;{{'COMPLETE_ALERT' |translate}}
                        </button>

                        <!-- to enable "SEND_TO_APP" btn the pipe #alreadyAdded checks if the alert is in the list of alerts with attached alarms -->
                        <!-- pipe triggers every time the length of the array of tasks with added alarms changes -->
                        <button
                                class="btn mr-1 ml-5 w-50 confirmBtn"
                                (click)="sendToApp(alert)"
                                [disabled]="alert.transitional || !(fireAlreadyAddedPipe | async | alreadyAdded : alert.alertId)">
                            <span class="fa-light fa-fw fa-mobile"></span>&nbsp;&nbsp;
                            {{'SEND_TO_APP' | translate }}
                        </button>
                    </div>
                    <div *ngIf="!(fireAlreadyAddedPipe | async | alreadyAdded : alert.alertId)" style="height: 1rem;">
                        <span class="validationNotice float-right mt-2">{{'CHOOSE_ONE_ALARM_REASON' | translate}}</span>
                    </div>

                    <hr />
                    <div>
                        <p class="mt-2" *ngIf="alert.customerName">
                            <span class="fa-light fa-fw fa-building"></span>&nbsp;&nbsp;{{alert.customerName}},&nbsp;{{alert.locationName}}
                        </p>
                        <!--Vega position information-->
                        <ng-container *ngIf="alert.deviceType === '06-1'">
                            <div class="pl-2 mt-4">
                                <strong>{{ "Vega " + ("LOCATION_INFO" | translate)}}</strong>
                                <hr class="mt-1 mb-3" />
                                <p class="m-0 mt-2">
                                    <strong>X</strong>: {{ alert.positionLatitude }}&deg;
                                    <strong class="ml-3">Y</strong>: {{ alert.positionLongitude }}&deg;&nbsp;&nbsp;
                                    <a *ngIf="alert.positionLink" [href]="alert.positionLink" target="_blank">
                                        {{
                                        "LOCATION_ON_MAP" | translate
                                        }}
                                    </a>
                                </p>
                                <iframe *ngIf="alert.positionLink" [src]="alert.positionLink | safe" allowfullscreen="1"
                                        title="Vega Position"></iframe>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showTeamAlerts">
            <div class="card-header card-header-dark">{{'ALERTS_TO_OTHERS' |translate}} ({{teamsAlerts.length}})</div>
            <div class="card-body card-body-dark" cdkDropList id="startedAlertList" [cdkDropListData]="alerts"
                 [cdkDropListConnectedTo]="['incomingAlertList', 'forwardedAlertList']"
                 (cdkDropListDropped)="crService.drop($event)">
                <div *ngFor="let alert of teamsAlerts">
                    <!--Alert card-->
                    <div class="alertCard">
                        <div class="alertCardHeader">
                            <div class="row">
                                <div class="col-12 clearfix">
                                    <div class="float-left mr-3">
                                        <img src="{{ alert | pickUpAlertIcon }}">
                                    </div>
                                    <div class="float-right cursorPointer"
                                         (click)="crSync.resetAlertIncrement(alert.userId, alert.alertId)"
                                         *ngIf="alert.increment > 0"
                                         matTooltip="{{'INCREMENT_INFO' |translate}} {{'CLICK_TO_RESET' |translate}}"
                                         [matTooltipPosition]="'above'">
                                        <span class="badge badge-pill badge-danger">{{'+' + alert.increment}}</span>
                                    </div>
                                    <div>
                                        <p class="alertHeader">
                                            <strong>{{alert.header}} ({{alert.productId}})</strong>
                                        </p>
                                        <p class="alertHeader"> {{ alert | pickUpActivationName}}</p>
                                        <small>{{ alert.time | calculateAlertTime}}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alertCardBody clearfix">
                            <div>
                                <p>
                                    <span class="fa-light fa-fw fa-user"></span>&nbsp;&nbsp;
                                    {{ getTeamMemberName(alert) }}
                                </p>
                            </div>
                            <div class="mb-3">
                                <app-cr-notes-list [alert]="alert"></app-cr-notes-list>
                                <app-cr-notes-form *ngIf="showNoteForm"
                                                   [alert]="alert"
                                                   [addedCameraVisitNotes]="addedCameraVisitNotes"
                                                   (onCloseByCancel)="hideForm()">
                                </app-cr-notes-form>
                            </div>
                            <div>
                                <p class="mt-2" *ngIf="alert.customerName">
                                    <span class="fa-light fa-fw fa-building"></span>&nbsp;&nbsp;{{alert.customerName}},&nbsp;{{alert.locationName}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Visit card-->
        <div *ngFor="let visit of visits">
            <div class="alertCard">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="d-flex flex-wrap justify-content-between align-items-center">
                            <div class="d-flex flex-column align-items-center">
                                <div class="d-flex flex-row align-items-center">
                                    <i class="fa-solid fa-2x fa-camera-cctv text-primary"></i>
                                    <div class="ml-2">
                                        <p class="startedVisitHeader"><strong>{{visit.type |translate}}</strong></p>
                                    </div>
                                    <span *ngIf="visit.snoozed" class="badge badge-pill badge-primary snooze-badge">+1</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center border-left">
                                <div class="mr-sm-4 my-2 pl-3">
                                    <p><small>{{'TIME_REMAINING' |translate}}</small></p>
                                    <p><strong>{{crService.getFormattedRemainingTime(visit.timeRemaining)}}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="clearfix">
                            <p class="alertHeader"><strong>{{visit.name}}</strong></p>
                        </div>
                        <div class="clearfix">
                            <div>
                                <p class="mt-2">
                                    <span class="fa-light fa-lg fa-fw fa-building"></span>&nbsp;&nbsp;{{visit.companyName}}
                                </p>
                            </div>
                            <div>
                                <p class="mt-2">
                                    <span class="fa-light fa-lg fa-fw fa-microchip"></span>&nbsp;&nbsp;{{visit.locationName}}
                                </p>
                            </div>
                        </div>
                        <div class="mt-3">
                            <iframe allowfullscreen="1"
                                    [src]='videoControllerBaseUrl+"/api/videopage?url="+visit.rtsp+"&authtoken="+visit.token+"&sessionid="+visit.token+"&lang="+language |safe'
                                    class="started" title="Camera visit"></iframe>
                            <button class="btn btn-light" *ngIf="visit.snoozable && !visit.snoozed" (click)="snoozeVisit(visit)" id="snoozer">{{'SNOOZE_VISIT_FOR_15_MIN' | translate}}</button>
                        </div>
                    <!-- <app-mark-alarm-reason></app-mark-alarm-reason>
                    <app-add-note></app-add-note> -->
                        <div class="d-flex justify-content-between">
                            <div class="startedVisitOk p-3 flex-fill mr-2 rounded text-center cursorPointer"
                                 (click)="crService.completeStartedVisit(visit)">
                                <span class="fa-solid fa-check"></span>
                                {{'EVERYTHING_OK' |translate}}
                            </div>
                            <div class="startedVisitEMA p-3 flex-fill ml-2 rounded text-center cursorPointer"
                                 (click)="crService.generateAlertFromVisit(visit)">
                                <span class="fa-solid fa-arrow-right"></span> {{'SEND_TO_APP' |translate}}
                            </div>
                        </div>
                        <div class="mt-2">
                            <p class="cursorPointerHover mt-2" (click)="crService.cancelStartedVisit(visit)">
                                <span class="fa-light fa-fw fa-reply"></span>&nbsp;&nbsp;
                                {{ 'CANCEL_RESERVATION' |translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
