import { NgModule, Pipe, PipeTransform } from "@angular/core";
import { ActivationOption, ActivationState, DeviceGroup, DeviceOption } from "../components/alarm-route/alarm-route-edit/alarm-route-devices/alarm-route-devices.component";
import { Activation } from "app/models/alarmroutes.model";

@Pipe({
  name: "mapDeviceActivation",
  pure: true,
})
export class DeviceActivationMapPipe implements PipeTransform {
  transform(alarmRouteDevices: DeviceOption[], allActivations: ActivationOption[], availableActivations: ActivationOption[]): Map<ActivationOption, ActivationState> {
    const selectedDevicesActivations: Map<Activation, ActivationState> = new Map();
    const activationsMap: Map<Activation, boolean[]> = new Map();

    availableActivations = allActivations?.filter(activation => activation.deviceType === alarmRouteDevices[0]?.deviceType);

    availableActivations?.forEach(availableActivation => {
      alarmRouteDevices?.forEach(alarmRouteDevice => {
        const activationActive: boolean
          = alarmRouteDevice.activations
            .some(activation =>
              activation.node === availableActivation.node
              && activation.group === availableActivation.group
            );

        if (activationsMap.has(availableActivation)) {
          activationsMap.get(availableActivation).push(activationActive)
        }
        else {
          availableActivation.bindDeviceIds = alarmRouteDevices
            .map(device => device.deviceId);
          activationsMap.set(availableActivation, [activationActive]);
        }
      });
    });

    for (let entry of Array.from(activationsMap.entries())) {
      const key = entry[0];
      const value = entry[1];

      let activationState: ActivationState;

      if (value.every(active => active)) {
        activationState = ActivationState.On;
      }
      else {
        if (value.some(active => active)) {
          activationState = ActivationState.Intermediate;
        }
        else {
          if (value.every(active => !active)) {
            activationState = ActivationState.Off;
          }
        }
      }
      selectedDevicesActivations.set(key, activationState)
    }
    return selectedDevicesActivations;
  }
}

@Pipe({
  name: "foundInGroups",
  pure: true,
})
export class DeviceFoundInGroupsPipe implements PipeTransform {
  transform(deviceId: string, deviceGroups: DeviceGroup[]): boolean {
    return deviceGroups?.some(x => x.deviceOptions?.some(y => y.selected && y.deviceId === deviceId));
  }
}

@Pipe({
  name: "foundInLocation",
  pure: true,
})
export class DeviceFoundInLocationPipe implements PipeTransform {
  transform(locationId: string, deviceGroups: DeviceGroup[]): boolean {
    return deviceGroups?.some(x => x.deviceOptions?.some(y => y.selected && y.locationId === locationId));
  }
}

@Pipe({
  name: "deviceTypeFoundInGroups",
  pure: false,
})
export class DeviceTypeFoundInGroupsPipe implements PipeTransform {
  transform(deviceType: string, deviceGroups: DeviceGroup[]): boolean {
    return deviceGroups?.some(x => x.deviceOptions.some(y => y.selected && y.deviceType === deviceType));
  }
}

@Pipe({
  name: "anySelectedDevices",
  pure: false,
})
export class AnySelectedDevicesPipe implements PipeTransform {
  transform(deviceGroups: DeviceGroup[]): boolean {
    return deviceGroups?.some(x => x.deviceOptions.some(y => y.selected));
  }
}

@Pipe({
  name: "availableDevicesInLocation",
  pure: true,
})
export class AvailableDevicesInLocationPipe implements PipeTransform {
  transform(locationId: string, deviceGroups: DeviceGroup[]): boolean {
    return deviceGroups?.some(x => x.deviceOptions.some(y => !y.selected && y.locationId === locationId));
  }
}

@Pipe({
  name: "anyAvailableDevices",
  pure: true,
})
export class AnyAvailableDevicesPipe implements PipeTransform {
  transform(deviceGroups: DeviceGroup[]): boolean {
    return deviceGroups?.some(x => x.deviceOptions.some(y => !y.selected));
  }
}

@Pipe({
  name: "anyAvailableDevicesByType",
  pure: true,
})
export class AnyAvailableDevicesByTypePipe implements PipeTransform {
  transform(deviceType: string, deviceGroups: DeviceGroup[]): boolean {
    return deviceGroups?.some(x => x.deviceOptions.some(y => !y.selected && y.deviceType === deviceType));
  }
}

@NgModule({
  declarations: [DeviceActivationMapPipe, DeviceFoundInGroupsPipe, DeviceFoundInLocationPipe, DeviceTypeFoundInGroupsPipe, AnySelectedDevicesPipe, AvailableDevicesInLocationPipe, AnyAvailableDevicesPipe, AnyAvailableDevicesByTypePipe],
  exports: [DeviceActivationMapPipe, DeviceFoundInGroupsPipe, DeviceFoundInLocationPipe, DeviceTypeFoundInGroupsPipe, AnySelectedDevicesPipe, AvailableDevicesInLocationPipe, AnyAvailableDevicesPipe, AnyAvailableDevicesByTypePipe],
})
export class AlarmRoutePipesModule { }
