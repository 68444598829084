import {
  IMultiSelectSettings,
  IMultiSelectTexts,
} from "ngx-bootstrap-multiselect";
import { Injectable } from "@angular/core";
import { OptionGroup } from "app/components/category-multiselect-button/category-multiselect-button-models";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { DateAdapter } from "@angular/material/core";
import { ApiService } from "./api.service";

@Injectable()
export class GlobalVariables {
  public reportFilters: ReportFilter;
}
export class ReportFilter {
  SumAvg?: string;
  device?: FilterOptions;
  location?: FilterOptions;
  alertRoute?: FilterOptions;
  basestation?: FilterOptions;
  personnel?: FilterOptions;
  deviceTypes?: FilterOptions;
  activations?: FilterOptions;
  eventTypes?: FilterOptions;
  alarmReasons?: FilterOptions;
  settings?: IMultiSelectSettings;
  singleSelectSettings?: IMultiSelectSettings;
}

export class CameraSupervisionFilter {
  location?: FilterOptions;
  cameraGroup?: FilterOptions;
  receiverGroup?: FilterOptions;
  alertRoute?: FilterOptions;
  settings?: IMultiSelectSettings;
  singleSelectSettings?: IMultiSelectSettings;
}

export class FilterOptions {
  filterList: any[] = [];
  selectedList: any[] = [];
  texts: IMultiSelectTexts;

  setFilterListByDescOrder(options: { id: string; name: string }[]) {
    this.filterList = options.sort((a, b) => {
      return b.name.localeCompare(a.name);
    });
  }
}

// Sorting constants e.g. for custom table sorting
export enum SortOrder {
  Desc = -1,
  Default,
  Asc,
}

export type UiLang = "en" | "fi" | "sv" | "ee";

export type AlertType = { deviceType: string; name: string; group: number; node: number };

//Get localized activation name based on group, node and device type
@Injectable()
export class HelperService {

  constructor(
    private translateService: TranslateService,
    private api: ApiService
  ) { }

  getActivationName(
    group: number,
    node: number,
    type: string,
    customAct: string,
    connTimeLimit: number
  ): string {
    //If device has custom activation name set return it
    if (customAct) {
      return customAct;
    }
    let array: any;
    //Load local storage into memory if it is not there yet
    if (!array) {
      array = JSON.parse(localStorage.getItem("act"));
    }
    if (array) {
      //Loop through the array of activations and find the one where group, node and device type matches with the requested values
      for (var i = 0; i < array.length; i++) {
        if (
          array[i].group === group &&
          array[i].node === node &&
          array[i].deviceType === type
        ) {
          //Some activation names has variable %CTL%. Replace that with connection time limit
          return array[i].name.replace(
            "%CTL%",
            this.convertConnTimeLimit(connTimeLimit)
          );
        }
      }
    }
    //Return group and node if translation not found
    return group + "-" + node;
  }

  getActivationsForDeviceType(type: string): any {
    let activations = JSON.parse(localStorage.getItem("act"));
    return activations.filter((obj) => {
      return obj.deviceType === type;
    });
  }

  getActivationsForDeviceTypes(types: string[]): AlertType[] {
    const activations = JSON.parse(localStorage.getItem("act"));
    return activations.filter((obj) => {
      return types.includes(obj.deviceType);
    });
  }

  convertConnTimeLimit(limit: number): string {
    //Get full hours from the seconds
    let hours = Math.floor(limit / 3600);
    //Get remaining seconds after full hours
    limit = limit % 3600;
    //Get full minutes from the remaining seconds
    let minutes = Math.floor(limit / 60);
    //Get remaining seconds
    let seconds = limit % 60;
    //Add strings to final result only if there's something
    let final = "";
    if (hours > 0) {
      final += hours + "h ";
    }
    if (minutes > 0) {
      final += minutes + "m ";
    }
    if (seconds > 0) {
      final += seconds + "s ";
    }
    return final;
  }

  convertSecondsToHHMMSS(s: number): string {
    //Get full hours from the seconds
    let hours = Math.floor(s / 3600);
    //Get remaining seconds after full hours
    s = s % 3600;
    //Get full minutes from the remaining seconds
    let minutes = Math.floor(s / 60);
    //Get remaining seconds
    let seconds = s % 60;
    return (
      this.addLeadingZero(hours) +
      ":" +
      this.addLeadingZero(minutes) +
      ":" +
      this.addLeadingZero(seconds)
    );
  }

  convertSecondsToReadableFormat(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    let formattedTime = "";
    let formattedSeconds = remainingSeconds.toString();

    if (hours > 0) {
      formattedTime += `${hours}h `;
    }

    if (minutes > 0 || hours > 0) {
      formattedTime += `${minutes}min `;
    }

    // Check if remaining seconds is a fraction number
    if (!Number.isInteger(remainingSeconds)) {
      formattedSeconds = remainingSeconds.toFixed(2).replace(".", ",");
    }

    return (formattedTime += `${formattedSeconds}s`);
  }

  getDaysBetweenDates(startDate: Date, endDate: Date): number {
    if (this.isValidDate(startDate) && this.isValidDate(endDate)) {
      // The number of milliseconds in one day
      const ONE_DAY = 1000 * 60 * 60 * 24;
      // Calculate the difference in milliseconds
      const differenceMs = endDate.getTime() - startDate.getTime();
      // Check difference only if the end date is not before the start date
      if (0 <= differenceMs) {
        // Convert back to days and return
        const days = Math.round(differenceMs / ONE_DAY);
        return isNaN(days) ? 0 : days;
      }
    }
    return 0;
  }

  isValidDate(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  /**
   * Adds days to given date
   * @param date Date to add days to
   * @param days Days to add
   * @returns New date with added days
   */
  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  addLeadingZero(number: number): string {
    return (number < 10 ? "0" : "") + number;
  }

  getDeviceType(type: string): string {
    let array = JSON.parse(localStorage.getItem("devType"));
    if (array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].deviceType === type) {
          if (array[i].deviceTypeName) {
            return (
              array[i].deviceTypeName + " (" + array[i].deviceTypeCode + ")"
            );
          } else {
            return array[i].deviceTypeCode;
          }
        }
      }
    }
    return type;
  }

  getDeviceTypeCode(type: string): string {
    let array = JSON.parse(localStorage.getItem("devType"));
    if (array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].deviceType === type) {
          return array[i].deviceTypeCode;
        }
      }
    }
    return type;
  }

  showIfEnabled(feature: string): boolean {
    let features: string[] = JSON.parse(localStorage.getItem("features"));
    if (features) {
      let index = features.indexOf(feature);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  // Splits array into smaller Arrays based on given property
  groupBy<T, K>(list: T[], getKey: (item: T) => K) {
    const map = new Map<K, T[]>();
    list.forEach((item) => {
      const key = getKey(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return Array.from(map.values());
  }

  isFeatureEnabled(feature: string): boolean {
    const features: [string] = JSON.parse(localStorage.getItem("features"));
    if (features) {
      return features.includes(feature);
    }
    return false;
  }

  getCustomerInfo(): any {
    return JSON.parse(localStorage.getItem("customerInfo"));
  }

  /**
   * Formats the given date string to the specified format
   * @param date Date to format
   * @param format Format to convert the date to
   * @returns Formatted date string
   */
  formatDate(date: string, format: string): string {
    // If the value is undefined (or any other falsy value),
    // the method returns an empty string
    if (!date) {
      return '';
    }
    // Return empty result if timestamp is 'null'
    if (date === "0001-01-01T00:00:00Z") return "-";

    // Set a default locale if currentLang is not defined
    let locale = this.translateService.currentLang
      ? this.translateService.currentLang.split('-')[0]
      : 'en';  // default locale

    // Use 'en-gb' for English language to use 24h format
    if (locale === "en") locale = "en-gb";

    // Check if the date string matches the 'DD.MM.YYYY HH.mm.ss' format
    const regex = /^\d{1,2}\.\d{1,2}\.\d{4} \d{1,2}\.\d{2}\.\d{2}$/;
    const dateFormat = regex.test(date) ? 'DD.MM.YYYY HH.mm.ss' : undefined;

    let momentDate = moment(date, dateFormat).locale(locale);

    if (momentDate.isValid()) return momentDate.format(format);
    else return date;
  }

  /**
   * Sets the locale for the date adapter
   * @param language UI language to determine the locale
   * @param adapter Date adapter to set the locale for
   */
  setDateAdapterLocale(language: UiLang, adapter: DateAdapter<any>): void {
    const locale = language === "en" ? "en-GB" : language;
    adapter.setLocale(locale);
  }

  /**
   * Returns the delivery class based on the alerts delivery type
   * @param alert Alert to get the delivery class for
   * @returns Delivery class for the alert, empty string if not found
   */
  getDeliveryClass(alert: any): string {
    switch (alert.deliveryType) {
      case 'LOG':
        return 'fa-file'
      case 'CALL+SMS':
        return 'fa-phone'
      case 'CALL+GRSMS':
        return 'fa-users';
      case 'EMA':
        return 'fa-mobile fa-lg';
      case 'SMSONLY':
      case 'SMS':
        return 'fa-comment';
      case 'EMAIL':
        return 'fa-envelope';
      default:
        return '';
    }
  }
}
