import { Injectable } from '@angular/core';
import { IUser, UserRoleLevel } from 'app/models/user.model';

/**
 * Service to handle user related operations and business logic
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  /**
   * Get the stored user from local storage
   */
  getStoredUser(): IUser {
    return JSON.parse(localStorage.getItem('user')) as IUser;
  }

  /**
   * Check if the user has the given role(s)
   * @param roles
   * @returns `true` if the user has the given role(s), otherwise `false`
   */
  isRole(...roles: UserRoleLevel[]): boolean {
    const user = this.getStoredUser();
    return roles.includes(user?.roleLevel);
  }
}
