<div class="row">
    <div class="col-12">
        <h6 class="text-center routeEditGroupTitle mb-2">{{'ALARM_INDICATION_GROUP' | translate}}</h6>
    </div>
</div>

<div class="row">
    <div class="col-lg-4 col-md-12">
        <div class="card p-2">
            <h6 class="text-center devicesGroupTitle">{{'AVAILABLE_DEVICES' | translate}}</h6>

            <mat-list dense="true">

                <mat-list-item *ngIf="availableDevicesEmpty()">

                    <div mat-line>
                        <span class="fa-light fa-check-double mr-1" style="vertical-align: middle"></span>

                        <span class="devicesGroupTitle text-truncate">{{'ADD_ALL_DEVICES' | translate}}</span>
                    </div>


                    <mat-icon class="add-device-button" (click)="addAllDevices();">add_circle</mat-icon>


                </mat-list-item>


                <ng-container *ngFor="let group of deviceGroups">
                    <mat-list-item *ngIf="availableDevicesInLocation(group.locationId)">

                        <div mat-line>
                            <span *ngIf="group.locationName"
                                class="fa-fw mr-2 fa-solid fa-house devicesGroupTitle"></span>

                            <span class="devicesGroupTitle text-truncate">{{group.locationName}}</span>
                        </div>


                        <mat-icon class="add-device-button" (click)="addDevicesByLocationId(group.locationId)">
                            add_circle</mat-icon>


                    </mat-list-item>

                    <ng-container *ngFor="let device of group.deviceOptions">

                        <mat-list-item *ngIf="!device.selected">

                            <div mat-line class="text-truncate">
                                <span [ngClass]="getDeviceIconClass(device.deviceType)" class="mr-1"
                                    style="vertical-align: middle"></span>

                                <ng-container *ngIf="device.baseStationName">{{device.baseStationName}}</ng-container>
                                <ng-container *ngIf="!device.baseStationName">{{device.baseStationId}}</ng-container>
                                ({{getDeviceType(device.deviceType)}})
                            </div>


                            <mat-icon class="add-device-button" (click)="addDevice(device)">add_circle</mat-icon>


                        </mat-list-item>

                    </ng-container>
                </ng-container>


            </mat-list>
        </div>
    </div>


    <!-- Selected devices -->

    <div class="col-lg-4 col-md-12">
        <div class="card p-2">
            <h6 class="text-center devicesGroupTitle">{{'SELECTED_DEVICES' | translate}}</h6>
            <mat-list dense="true">

                <mat-list-item *ngIf="selectedDevicesEmpty()">
                    <mat-icon class="remove-device-button" (click)="removeAllDevices();">remove_circle</mat-icon>

                    <div mat-line>
                        <span class="fa-light fa-check-double mr-1" style="vertical-align: middle"></span>

                        <span class="devicesGroupTitle text-truncate">{{'REMOVE_ALL_DEVICES' | translate}}</span>
                    </div>
                </mat-list-item>

                <ng-container *ngFor="let group of deviceGroups">
                    <mat-list-item *ngIf="selectedDevicesInLocation(group.locationId)">

                        <div mat-line>
                            <span *ngIf="group.locationName"
                                class="fa-fw mr-2 fa-solid fa-house devicesGroupTitle"></span>

                            <span class="devicesGroupTitle text-truncate">{{group.locationName}}</span>
                        </div>

                        <mat-icon class="remove-device-button" (click)="removeDevicesByLocationId(group.locationId)">
                            remove_circle</mat-icon>

                    </mat-list-item>

                    <ng-container *ngFor="let device of group.deviceOptions">
                        <div [ngClass]="{'highlight-selection' : device.highlight}">
                            <mat-list-item *ngIf="device.selected">
                                <div
                                  mat-line class="text-truncate selection-container"
                                  (click)="
                                    highlightDeviceOption(device);
                                    selectedDeviceForSettings = device"
                                >
                                    <span
                                        [ngClass]="getDeviceIconClass(device.deviceType)"
                                        class="mr-1" style="vertical-align: middle">
                                    </span>

                                    <div
                                        class="d-inline">
                                        <ng-container *ngIf="device.baseStationName">{{device.baseStationName}}
                                        </ng-container>
                                        <ng-container *ngIf="!device.baseStationName">{{device.baseStationId}}
                                        </ng-container>
                                        <span> ({{getDeviceType(device.deviceType)}})</span>
                                    </div>
                                </div>

                                <mat-icon class="remove-device-button" (click)="removeDevice(device)">remove_circle
                                </mat-icon>
                            </mat-list-item>
                        </div>

                    </ng-container>
                </ng-container>


            </mat-list>
        </div>
    </div>

    <div class="col-lg-4 col-md-12">
        <div class="card p-2 options-list">
            <h6 class="text-center devicesGroupTitle">{{'SETTINGS' | translate}}</h6>
            <p *ngIf="!selectedDeviceByDeviceId(selectedDeviceForSettings?.baseStationId)" class="text-center">
                {{'NO_DEVICES_SELECTED' | translate}} </p>


            <ng-container *ngIf="selectedDeviceByDeviceId(selectedDeviceForSettings?.baseStationId)">

                <ng-container *ngIf="selectedDeviceForSettings">
                    <p *ngIf="selectedDeviceForSettings.baseStationName" class="text-center">
                        {{selectedDeviceForSettings.baseStationName}}</p>
                    <p *ngIf="!selectedDeviceForSettings.baseStationName" class="text-center">
                        {{selectedDeviceForSettings?.baseStationId}}</p>
                </ng-container>

                <mat-slide-toggle [checked]="selectedDeviceForSettings.buzzerAndLed"
                    class="ml-3 option"
                    name="buzzerOption"
                    #buzzerOption
                    (change)="changeHighlightedBaseStationBuzzerOption(buzzerOption.checked)">
                    <span *ngIf="selectedDeviceForSettings.buzzerAndLed">{{'BUZZER_AND_LED' | translate}}</span>
                    <span *ngIf="!selectedDeviceForSettings.buzzerAndLed">{{'BUZZER_ONLY' | translate}}</span>

                </mat-slide-toggle>


                <div>
                    <mat-form-field class="full-width mt-4 mb-2">
                        <mat-label>{{'ALARM_TYPE' |translate}}</mat-label>
                        <mat-select class="option" [value]="getHighlightedBaseStationAlarmOption()"

                            (selectionChange)="changeHighlightedBaseStationAlarmOption(alarmOnHomeBase.selected, alarmOnRoamingBase.selected, alarmOnBoth.selected)">
                            <mat-option #alarmOnHomeBase [value]="0">{{'ALARM_ON_HOMEBASE' | translate}}</mat-option>
                            <mat-option #alarmOnRoamingBase [value]="1">{{'ALARM_ON_ROAMINGBASE' | translate}}
                            </mat-option>
                            <mat-option #alarmOnBoth [value]="2">{{'ALARM_ON_HOMEBASE' | translate}} &
                                {{'ALARM_ON_ROAMINGBASE' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </ng-container>


        </div>

    </div>

</div>
