<div *ngIf="updateSuccess" class="alert alert-success">
    {{'CONF_SAVED' |translate}}
</div>
<div *ngIf="updateError" class="alert alert-danger">
    {{'ERROR' |translate}}
</div>

<form [formGroup]="iotCommsForm" (ngSubmit)="formSubmitSubject.next()" *ngIf="iotCommsConfigurationLoaded">
    <mat-slide-toggle class="pb-4" (change)="updateScaipDependentValidators()" formControlName="scaipEnabled">
        <strong>SCAIP</strong>
    </mat-slide-toggle>

    <div class="form-group">
        <label><strong>{{'CONFIG_TYPE' |translate}}</strong></label>
        <select id="config-type-selector" class="form-control" formControlName="configType">
            <option [value]="''">{{ "DEFAULT" | translate }}</option>
            <option value="Jontek">Jontek</option>
            <option value="Enovation">Enovation</option>
        </select>
    </div>

    <div class="form-group" *ngIf="!scaipEnabled">
        <label><strong>{{'ARC_DESTINATION_NUMBER' |translate}}</strong></label>
        <input class="form-control" type="text" formControlName="destination">
    </div>

    <div class="form-group" *ngIf="!scaipEnabled">
        <label><strong>{{'ARC_DESTINATION_DOMAIN' |translate}}</strong></label>
        <p>{{'ARC_DESTINATION_DOMAIN_HELP' |translate}}</p>
        <input class="form-control" type="text" formControlName="domain">
    </div>

    <div class="form-group">
        <label><strong>{{'SCHEME_ID' |translate}}</strong></label>
        <input class="form-control" type="text" formControlName="schemeId">
    </div>

    <div class="form-group">
        <label><strong>Audio</strong></label>
        <input class="form-control" type="text" formControlName="audio">
    </div>

    <div class="form-group">
        <label><strong>{{'RETRY_TIME' |translate}}</strong></label>
        <input class="form-control" type="text" formControlName="retryTime">
    </div>

    <ng-container *ngIf="scaipEnabled">
        <h6 id="SCAIP-header">
            <strong>SCAIP {{ "SETTINGS" | translate }}</strong>
        </h6>
        <hr class="mt-0 mb-3" />

        <div class="form-group mt-4">
            <label><strong>{{ "EVENT_RECEIVER" | translate }}</strong></label>
            <input class="form-control" type="text" formControlName="eventReceiver">
        </div>

        <div class="form-group">
            <label><strong>{{ "CALLBACK_SIP" | translate }}</strong></label>
            <input class="form-control" type="text" formControlName="callbackSIP">
        </div>
    </ng-container>

    <div class="form-group mb-1 mt-2">
        <button [disabled]="(iotCommsForm.invalid || iotCommsForm.pristine)" type="submit" class="btn btn-outline-primary mr-1"><span class="fa-light fa-floppy-disk"></span> {{'SAVE' |translate}}</button>
        <span class="btn btn-primary float-right" (click)="syncToIotCOmms()"><span class="fal fa-rotate"></span> {{'SYNC_TO_IOTCOMMS' |translate}}</span>
    </div>

    <div class="form-group alert alert-info" *ngIf="provisionDone">
        {{'PROVISION_COMPLETED' |translate}}, {{'SUCCESS' |translate}}: {{provisionSuccess}} - {{'FAILED' |translate}}: {{provisionFailed}}
    </div>
</form>
