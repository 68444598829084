import { Component, Input, OnInit } from '@angular/core';
import { Route } from '../../../../models/alarmroutes.model';
import { StaffModel } from '../../../../models/staff.model';
import { ReceiverTeam } from '../../../../models/receiver-team';
import { AlarmRouteRecipientsComponent } from '../alarm-route-recipients/alarm-route-recipients.component';

@Component({
    selector: 'alarm-route-fallback-recipients',
    templateUrl: './alarm-route-fallback-recipients.component.html',
    styleUrls: ['./alarm-route-fallback-recipients.component.css'],
    standalone: true,
    imports: [AlarmRouteRecipientsComponent]
})
export class AlarmRouteFallbackRecipientsComponent {

  @Input('tempRoute') tempRoute: Route;
  @Input('allStaff') allStaff: StaffModel[] = [];
  @Input('allReceiverTeams') allReceiverTeams: ReceiverTeam[] = [];
  @Input('customerInformation') customerInformation: any;
}
