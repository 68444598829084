import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from 'app/services/api.service';
import { AnimationCollapse, AnimationCollapseLeave } from 'app/animations';
import { DeliveryClassPipe, MomentPipe } from 'app/pipes/pipes';
import { HelperService } from 'app/services/helper.service';

@Component({
  selector: 'app-active-alerts',
  standalone: true,
  imports: [CommonModule, TranslateModule, DeliveryClassPipe, MomentPipe],
  templateUrl: './active-alerts.component.html',
  styleUrls: ['./active-alerts.component.css'],
  animations: [AnimationCollapse, AnimationCollapseLeave]
})
export class ActiveAlertsComponent {

  constructor(private api: ApiService, private helper: HelperService) { }

  user: object = JSON.parse(localStorage.getItem('user'));

  panelOpen: boolean;
  activeAlerts: object[];
  activeAlertsLoaded: boolean = false;

  /**
   * Load all active alerts for the customer
   */
  loadActiveAlerts(): void {
    this.activeAlertsLoaded = false;
    // Check that active alerts panel has been opened from the UI
    if (!this.panelOpen) {
      // Load the alerts
      this.api.getActiveAlerts()
        .subscribe(res => {
          this.activeAlertsLoaded = true;
          if (res) {
            this.activeAlerts = res;
          } else {
            this.activeAlerts = null;
          }
        })
    } else {
      // If panel was closed clear the alerts from the memory
      this.activeAlerts = null;
    }
    this.panelOpen = !this.panelOpen;
  }

  /**
   * Cancel an active alert
   * @param alert The alert to cancel
   */
  cancelAlert(alert: any): void {
    this.api.cancelActiveAlert(alert.taskId)
      .subscribe(_ => {
        alert.cancelled = true;
      },
        err => {
          alert.error = err;
        })
  }

  /**
   * Get localized activation name based on group, node and device type
   * @param group activation group
   * @param node activation node
   * @param type activation type
   * @param customAct custom activation name
   * @param connTimeLimit connection time limit
   * @returns the localized activation name
   */
  getActivationName(group: number, node: number, type: string, customAct: string, connTimeLimit: number): string {
    return this.helper.getActivationName(group, node, type, customAct, connTimeLimit);
  }
}
