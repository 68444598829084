import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlarmRouteDevice, CallCenterDeliveryType, RecipientType, Route, ServiceTime } from '../../../models/alarmroutes.model';
import { CallCenter } from '../../../models/callcenter.model';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AlertTypePipe, DeviceIconClassPipe, DeviceTypePipe } from 'app/pipes/pipes';

@Component({
  selector: 'alarm-route-info',
  templateUrl: './alarm-route-info.component.html',
  styleUrls: ['./alarm-route-info.component.css'],
  standalone: true,
  imports: [CommonModule, TranslateModule, DeviceIconClassPipe, DeviceTypePipe, AlertTypePipe]
})
export class AlarmRouteInfoComponent implements OnInit, OnChanges {

  constructor(private api: ApiService, private router: Router,) { }

  @Input('route') route: Route;
  @Input('callCenters') callCenters: CallCenter[];
  @Output() routeDeleted = new EventEmitter<string>();
  @Output() closeInformationForm = new EventEmitter<void>();
  @Output() showEditForm = new EventEmitter<void>();
  @Output() activeTabIndexEmitter = new EventEmitter<number>();


  callCenterDeliveryTypeTranslationsMap: Map<CallCenterDeliveryType, string> = new Map([
    [CallCenterDeliveryType.UnableToDeliverAlarm, 'UNABLE_TO_DELIVER_ALARM'],
    [CallCenterDeliveryType.CallCenterFirst, 'CALL_CENTER_FIRST'],
    [CallCenterDeliveryType.CallCenterOnly, 'CALL_CENTER_ONLY'],
    [CallCenterDeliveryType.WhenUnableToDeliverEMACallCenterOnly, 'UNABLE_TO_DELIVER_EMA_CALL_CENTER_ONLY'],
    [CallCenterDeliveryType.WhenUnableToDeliverEMAAlarm, 'UNABLE_TO_DELIVER_EMA_ALARM'],
  ]);


  daysTranslationMap: Map<string, string> = new Map([
    ['mon', 'DAYOFWEEK_1'],
    ['tue', 'DAYOFWEEK_2'],
    ['wed', 'DAYOFWEEK_3'],
    ['thu', 'DAYOFWEEK_4'],
    ['fri', 'DAYOFWEEK_5'],
    ['sat', 'DAYOFWEEK_6'],
    ['sun', 'DAYOFWEEK_7'],
  ]);

  user: any;
  activeTabIndex: number = 0;
  activations: any;
  deleteConfirmation: boolean;
  showAdvancedSettings: boolean = false;

  emitActiveTabIndex(): void {
    this.activeTabIndexEmitter.emit(this.activeTabIndex);
  }

  getCallCenterInformation(id: string): CallCenter {
    return this.callCenters.find(callCenter => callCenter.id === id);
  }

  recipientType(type: string): string {
    return type === RecipientType.User ? 'fal fa-user fa-fw ' : 'fal fa-users fa-fw ';
  }

  serviceTimeClass(serviceTime: ServiceTime) {
    return serviceTime.startTime === serviceTime.endTime ? 'fas fa-xs fa-circle text-danger' :
      'fas fa-xs fa-circle text-success'
  }


  enabledIndicator(enabled: boolean) {
    return enabled ?
      'fas fa-xs fa-circle text-success' : 'fas fa-xs fa-circle text-danger';
  }

  deleteRoute(): void {
    this.api.deleteAlarmRoute(this.route.id).subscribe(res => {
      this.routeDeleted.emit(this.route.id);
      this.closeInfoForm();
    }, err => {
      // this.translate.get(['ERROR']).subscribe(t => {
      //   this.editTeamFormError = t.ERROR;
      // })
    })
  }

  getCallCenterDeliveryType(callCenterFirst: boolean, keepAlarmOpenAfterCallCenter: boolean, emaBeforeCallCenter: boolean): CallCenterDeliveryType {
    if (!callCenterFirst && !keepAlarmOpenAfterCallCenter && !emaBeforeCallCenter) {
      return CallCenterDeliveryType.UnableToDeliverAlarm;
    }

    if (callCenterFirst && keepAlarmOpenAfterCallCenter && !emaBeforeCallCenter) {
      return CallCenterDeliveryType.CallCenterFirst;
    }

    if (callCenterFirst && !keepAlarmOpenAfterCallCenter && !emaBeforeCallCenter) {
      return CallCenterDeliveryType.CallCenterOnly;
    }

    if (callCenterFirst && !keepAlarmOpenAfterCallCenter && emaBeforeCallCenter) {
      return CallCenterDeliveryType.WhenUnableToDeliverEMACallCenterOnly;
    }

    if (callCenterFirst && keepAlarmOpenAfterCallCenter && emaBeforeCallCenter) {
      return CallCenterDeliveryType.WhenUnableToDeliverEMAAlarm;
    }
  }

  parseServiceTime(serviceTime: ServiceTime): string {
    serviceTime.startTime = this.trimTime(serviceTime.startTime);
    serviceTime.endTime = this.trimTime(serviceTime.endTime);
    if (serviceTime.startTime.startsWith('00:00') && serviceTime.endTime.startsWith('00:00')) {
      return 'NEVER';
    }

    if (serviceTime.startTime.startsWith('00:00') && serviceTime.endTime.startsWith('23:59')) {
      return 'ALWAYS';
    }

    return `${serviceTime.startTime} - ${serviceTime.endTime}`;
  }

  emaFeatureEnabled(): boolean {
    let features: [string] = JSON.parse(localStorage.getItem('features'));
    if (features) {
      return features.includes('EveronMobileApp');
    }
    return false;
  }

  // Track by functions for ngFor to prevent re-rendering of the whole list when a single item changes
  trackByDeviceId(_: number, device: AlarmRouteDevice) {
    return device.deviceId;
  }

  openDeviceTab(): void {
    const devices = this.route.devices;
    const sizeIncrement = 100;
    let batchSize = 25;
    let index = 0;

    // Add devices in batches to prevent UI from freezing when there are a lot of devices
    function addBatch(arrayToAdd: AlarmRouteDevice[]) {
      if (index < devices.length) {
        const nextBatch = devices.slice(index, index + batchSize);
        arrayToAdd.push(...nextBatch);
        index += batchSize;
        batchSize = sizeIncrement;
        setTimeout(() => addBatch(arrayToAdd), 0)
      }
    }

    this.route.devices = [];
    this.activeTabIndex = 1;

    addBatch(this.route.devices);
  }

  openDevicePage(deviceId: string): void {
    this.router.navigate(['device/' + deviceId]);
  }

  openPersonnelPage(staffId: string): void {
    this.router.navigate(['staff/' + staffId]);
  }

  openTeamPage(teamId: string): void {
    this.router.navigate(['teams/' + teamId]);
  }

  // 00:00:00 => 00:00
  trimTime(time: string): string {
    return time.substring(0, Math.max(time.length - 3, 5));
  }


  showEditingForm(): void {
    this.showEditForm.emit();
  }

  closeInfoForm(): void {
    this.closeInformationForm.emit();
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activeTabIndex = 0;
    this.emitActiveTabIndex();
  }

}
