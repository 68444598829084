import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Device } from '../../../../models/device.model';
import { ApiService } from '../../../../services/api.service';
import { Activation, AlarmRouteDevice, Route } from '../../../../models/alarmroutes.model';
import { AddDeviceAnimation } from '../../../../animations';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLineModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { KeyValuePipe, CommonModule } from '@angular/common';
import { AlarmRoutePipesModule } from '../../../../modules/alarm-route-pipes.module';
import { DeviceIconClassPipe, DeviceTypePipe } from 'app/pipes/pipes';
import { ScrollingModule } from '@angular/cdk/scrolling';

/*
* deviceGroups are populated at ngOnInit
* deviceGroups are mirrored to Available devices and Selected devices.
* When user adds an device from Available devices to Selected devices the devices selected variable will be changed to true
*
* If device is selected then it's not showed as Available device.
*
* Reason why we're hiding elements instead of parsing them again, when any of the deviceGroups devices change is performance.
*
* Every time when user makes a change to Devices (Selects, removes, activates an option), saveDevicesToRoute() is triggered,
* which takes all the selected devices (selected = true)  from deviceGroups  and  saves them to route.
* */
@Component({
  selector: 'alarm-route-devices',
  templateUrl: './alarm-route-devices.component.html',
  styleUrls: ['./alarm-route-devices.component.css'],
  animations: [AddDeviceAnimation],
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatLineModule,
    MatIconModule,
    MatCheckboxModule,
    ScrollingModule,
    KeyValuePipe,
    TranslateModule,
    AlarmRoutePipesModule,
    DeviceTypePipe,
    DeviceIconClassPipe
  ]
})
export class AlarmRouteDevicesComponent implements OnInit, OnChanges {

  @Input('tempRoute') tempRoute: Route;
  @Input('deviceList') rawDeviceList: Device[];

  // Caches for device data
  deviceGroups: DeviceGroup[] = [];
  deviceGroupMap: Map<string, DeviceGroup> = new Map();
  deviceOptionMap: Map<string, DeviceOption> = new Map();

  selectedDevicesForActivations: DeviceOption[] = [];
  deviceTypeGroupHighlighted: boolean = false;

  availableDeviceTypeOptions: DeviceTypeOption[] = [];
  deviceTypeAvailableActivations: ActivationOption[] = [];

  allActivations: ActivationOption[] = [];

  get allDeviceOptions(): DeviceOption[] {
    return Array.from(this.deviceOptionMap.values()) ?? [];
  }

  /*
   * Creates a device groups from device list.
   * Ex.
   *
   * LocationA
   *  - Device1
   *  - Device2
   * LocationB
   *  - Device3
   *  - Device4
   * */
  getDeviceGroups(rawDeviceList: Device[]): void {
    if (!rawDeviceList) {
      this.deviceGroups = [];
      return;
    }

    const deviceMap: Map<string, DeviceOption> = new Map();
    const deviceGroupMap: Map<string, DeviceGroup> = new Map();

    // Filter out key devices and devices that are at the same location as the route except for common location
    rawDeviceList = rawDeviceList.filter(device =>
      !device.deviceType.startsWith('F0-1') &&
      (!this.tempRoute.locationId.startsWith('C22') || device.locationId === this.tempRoute.locationId)
    );

    for (const device of rawDeviceList) {
      const deviceId = device.id;
      const deviceOption: DeviceOption = {
        activations: [],
        connectionTimeLimit: device.connectionTimeLimit,
        deviceId: device.id,
        deviceName: device.name,
        deviceType: device.deviceType,
        locationId: device.locationId,
        locationName: device.locationName
      };

      if (!deviceId || deviceId === '00000000') {
        deviceMap.set(deviceId, deviceOption);
      }
      else {
        const existingDevice = deviceMap.get(deviceId)
        if (existingDevice) {
          !existingDevice.pairedDeviceOptions && (existingDevice.pairedDeviceOptions = []);
          existingDevice.pairedDeviceOptions.push(deviceOption);
        }
        else {
          deviceMap.set(deviceOption.deviceId, deviceOption);
        }
      }
    }

    // Group devices by locationId
    deviceMap.forEach(dev => {
      dev.selected = false;
      if (!deviceGroupMap.has(dev.locationId)) {
        deviceGroupMap.set(dev.locationId, { locationId: dev.locationId, locationName: dev.locationName, deviceOptions: [dev] });
      } else {
        deviceGroupMap.get(dev.locationId)!.deviceOptions.push(dev);
      }
    });

    // Cache for later use
    this.deviceGroups = Array.from(deviceGroupMap.values()).sort((a, b) => a.locationName.localeCompare(b.locationName));
    this.deviceGroupMap = deviceGroupMap;
    this.deviceOptionMap = deviceMap;
  }

  /*
  * Gets all different device types available.
  * */
  getAvailableDeviceTypes(): DeviceTypeOption[] {
    const devices = this.deviceOptionMap;
    let availableDeviceTypes: Map<string, DeviceTypeOption> = new Map();

    devices.forEach(device => {
      if (!availableDeviceTypes.has(device.deviceType)) {
        availableDeviceTypes.set(device.deviceType, { deviceType: device.deviceType });
      }
    });

    return availableDeviceTypes.size > 0 ? Array.from(availableDeviceTypes.values()) : [];
  }

  /*
  * Gets all devices by type
  */
  getDevicesByType(deviceType: string): DeviceOption[] {
    const devices = this.allDeviceOptions;
    return devices.filter(device => device.selected && device.deviceType === deviceType);
  }

  // Adds Devices
  addDevice(device: DeviceOption): void {
    device.selected = true;
  }

  addAllDevices(): void {
    const devices = this.deviceOptionMap;
    devices.forEach(device => this.addDevice(device));
    this.saveDevicesToTempRoute();
  }

  addDevicesByLocationId(locationId: string): void {
    const devices = this.allDeviceOptions.filter(device => device.locationId === locationId);
    devices.forEach(device => this.addDevice(device));
    this.saveDevicesToTempRoute();
  }

  addDevicesByType(deviceType: string): void {
    const devices = this.allDeviceOptions.filter(device => device.deviceType === deviceType);
    devices.forEach(device => this.addDevice(device));
    this.saveDevicesToTempRoute();
  }

  // Removes Devices
  removeDevice(device: DeviceOption): void {
    device.selected = false;
    this.saveDevicesToTempRoute();
  }

  removeAllDevices(): void {
    const devices = this.allDeviceOptions;
    devices.forEach(device => {
      this.removeDevice(device);
    });
  }

  removeDevicesByLocationId(locationId: string): void {
    const devices = this.allDeviceOptions.filter(device => device.locationId === locationId);
    devices.forEach(device => this.removeDevice(device));
  }

  removeDevicesByType(deviceType: string): void {
    const devices = this.allDeviceOptions.filter(device => device.deviceType === deviceType);
    devices.forEach(device => this.removeDevice(device));
  }

  /**
   * Check if there is deviceId (device.selected = false) in deviceGroups
   */
  availableDeviceByDeviceId(deviceId: string): boolean {
    const device = this.allDeviceOptions.find(device => !device.selected && device.deviceId === deviceId);
    return !!device;
  }

  highlightDeviceTypeOption(deviceTypeOption: DeviceTypeOption): void {
    const devices = this.allDeviceOptions.filter(device => device.selected);
    devices.forEach(device => device.highlight = false);

    this.availableDeviceTypeOptions.forEach(deviceTypeOption => deviceTypeOption.highlight = false);
    deviceTypeOption.highlight = true;
  }


  highlightDeviceOption(deviceOption: DeviceOption): void {
    const devices = this.allDeviceOptions.filter(device => device.selected);
    devices.forEach(device => device.highlight = false);

    this.availableDeviceTypeOptions.forEach(deviceTypeOption => deviceTypeOption.highlight = false);
    deviceOption.highlight = true;
  }

  addActivationToDevice(deviceId: string, addActivation: Activation): void {
    const device = this.deviceOptionMap.get(deviceId);

    if (!device?.activations?.some(activation => activation.group === addActivation.group && activation.node == addActivation.node)) {
      device.activations?.push({
        group: addActivation.group,
        node: addActivation.node,
        deviceId: deviceId,
        deviceType: addActivation.deviceType,
        contactLimit: addActivation.contactLimit
      });
    }
  }

  addActivationToDevices(deviceIds: string[], addActivation: Activation): void {
    deviceIds.forEach(deviceId => {
      this.addActivationToDevice(deviceId, addActivation);
    });

    this.saveDevicesToTempRoute();
  }

  removeActivationFromDevice(deviceId: string, removeActivation: Activation): void {
    const device = this.allDeviceOptions.find(device => device.deviceId === deviceId);

    if (device) {
      const removeActivationIndex: number = device.activations.findIndex(activation => {
        return activation.group === removeActivation.group && activation.node === removeActivation.node
      });

      if (removeActivationIndex !== -1) {
        device.activations.splice(removeActivationIndex, 1);
      }
    }
  }

  removeActivationFromDevices(deviceIds: string[], removeActivation: Activation): void {
    deviceIds.forEach(deviceId => {
      this.removeActivationFromDevice(deviceId, removeActivation);
    });

    this.saveDevicesToTempRoute();
  }

  addInitialDevices(): void {
    const devices = this.deviceOptionMap;

    this.tempRoute.devices.forEach(initialDevice => {
      let device = devices.get(initialDevice.deviceId);

      if (device) {
        this.addDevice(device);

        initialDevice.activations.forEach(activation => {
          this.addActivationToDevice(device.deviceId, activation);
        });
      }
    });
    this.saveDevicesToTempRoute();
  }

  saveDevicesToTempRoute(): void {
    this.tempRoute.devices = this.allDeviceOptions.filter(device => device.selected);
  }

  trackByLocationId(_: number, deviceGroup: DeviceGroup) {
    return deviceGroup.locationId;
  }

  trackByDeviceFn(_: number, device: DeviceOption) {
    return device.deviceId + "_" + device.selected;
  }

  ngOnInit() {
    // Deep copy rawDeviceList so that we won't keep the reference
    this.getDeviceGroups(JSON.parse(JSON.stringify(this.rawDeviceList)));
    this.addInitialDevices();
    this.availableDeviceTypeOptions = this.getAvailableDeviceTypes();
    this.allActivations = JSON.parse(localStorage.getItem('act'));
  }

  // Re-parse devices on location change
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tempRoute.previousValue) {
      const tempDeviceList = this.allDeviceOptions.filter(device => device.selected);

      this.getDeviceGroups(JSON.parse(JSON.stringify(this.rawDeviceList)));
      this.availableDeviceTypeOptions = this.getAvailableDeviceTypes();
      this.tempRoute.devices = tempDeviceList.filter(device => this.availableDeviceByDeviceId(device.deviceId));
      this.addInitialDevices();
    }
  }
}

export interface DeviceOption extends AlarmRouteDevice {
  pairedDeviceOptions?: DeviceOption[];
  activations: ActivationOption[];
  highlight?: boolean;
  selected?: boolean;
}

export interface DeviceGroup {
  locationName: string;
  locationId: string;
  deviceOptions: DeviceOption[];
}

interface DeviceTypeOption {
  deviceType: string;
  highlight?: boolean;
}

export interface ActivationOption extends Activation {
  bindDeviceIds?: string[];
}

export enum ActivationState {
  Off,
  On,
  Intermediate
}
