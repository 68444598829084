import { Component, OnChanges, Input, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AnimationCollapse } from '../../../animations';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Device, BitParaSettings } from '../../../models/device.model';
import { HelperService } from '../../../services/helper.service';
import { Subject } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { LockBeaconSettingsComponent } from './lock-beacon-settings/lock-beacon-settings.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'device-settings',
    templateUrl: './device-settings.component.html',
    styleUrls: ['./device-settings.component.css'],
    animations: [
        AnimationCollapse
    ],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatExpansionModule, MatSlideToggleModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatTooltipModule, NgClass, NgFor, NgxMaterialTimepickerModule, LockBeaconSettingsComponent, TranslateModule]
})
export class DeviceSettingsComponent implements OnChanges, OnInit, OnDestroy {

  // Init global variables and services
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private fb: UntypedFormBuilder
  ) { };


  @Input('device') device: Device;

  deviceSettingsForm: UntypedFormGroup;
  saveSuccess: boolean;
  saveError: boolean;
  user: any;

  formSubmitSubject = new Subject();

  createDeviceSettingsForm(): void {
    let iAmOkEnabled: boolean;
    if (this.device.iamOk) {
      iAmOkEnabled = (this.device.iamOk.start !== "00:00" || this.device.iamOk.end !== "00:00");
    }
    this.deviceSettingsForm = this.fb.group({
      devBitParaForm: this.fb.group({
        led: this.device.bitParaSettings?.led,
        alarmBeep: this.device.bitParaSettings?.alarmBeep,
        presenceMsg: this.device.bitParaSettings?.presenceMsg,
        clock: this.device.bitParaSettings?.clock,
        autoAnswer: this.device.bitParaSettings?.autoAnswer,
        ledPulseAlert: this.device.bitParaSettings?.ledPulseAlert,
        qmsgEnabled: this.device.bitParaSettings?.qmsgEnabled,
        ringerVolume: this.device.bitParaSettings?.hasOwnProperty('ringerVolume') ? this.device.bitParaSettings.ringerVolume.toString() : "",
        battLedDisabled: this.device.bitParaSettings?.battLedDisabled,
        homeCare: this.device.bitParaSettings?.homeCare,
        clockBrightness: this.device.bitParaSettings?.hasOwnProperty('clockBrightness') ? (this.device.bitParaSettings?.clockAls ? "4" : this.device.bitParaSettings?.clockBrightness.toString()) : "",
        roamingDisabled: this.device.bitParaSettings?.roamingDisabled,
      }),
      devMiscSettingsForm: this.fb.group({
        limit: [this.helper.convertSecondsToHHMMSS(this.device?.movementTimeLimit).slice(0, -3), Validators.compose([Validators.required, Validators.pattern(new RegExp('^([0-9]{2}):([1-5]{1}[0-9]{1})|([0-9]{1}[1-9]{1}):([0-9]{2})|([1-9]{1}[0-9]{1}):([0-9]{2})$'))])],
        connTimeLimit: [this.helper.convertSecondsToHHMMSS(this.device?.connectionTimeLimit).slice(0, -3), Validators.compose([Validators.required, Validators.pattern(new RegExp('([0-9]{2}):([1-5]{1}[0-9]{1}|00)$'))])],
        activityMonitoring: this.device?.activityMonitoring
      }),
      terminalParameterFormGroup: this.fb.group({
        lockEscort: this.device.terminalParameters?.lockEscort,
        fallingDetection: !this.device.terminalParameters?.lockEscort ? this.device.terminalParameters?.fallingDetection : false,
        movementMonitoring: !this.device.terminalParameters?.lockEscort ? this.device.terminalParameters?.movementMonitoring : false,
        accessControl: !this.device.terminalParameters?.lockEscort ? this.device.terminalParameters?.accessControl : false,
        longPress: this.device.terminalParameters?.longPress,
        alwaysRoaming: this.device.terminalParameters?.alwaysRoaming
      }),
      tp202AdditionalForm: this.fb.group({
        ignoreAutoAnswer: this.device.ignoreAutoAnswerNumbers ? this.fb.array(this.device.ignoreAutoAnswerNumbers) : "",
        dtmfCode: this.device.audioLockOpenDtmfString,
        delayACPowerAlert: this.device.delayACPowerAlert,
        maxCallDuration: this.device.maxCallDuration,
        maxCallDurationExtended: this.device.maxCallDurationExtended,
        iAmOkEnabled: iAmOkEnabled,
        iAmOkStartTime: iAmOkEnabled ? this.device.iamOk.start : "",
        iAmOkEndTime: iAmOkEnabled ? this.device.iamOk.end : "",
      }),
      devNetworkSettingsForm: this.fb.group({
        transportPriority1: [{ value: this.device.transportOrder?.priority1.toString(), disabled: false }, [Validators.required]],
        transportPriority2: [{ value: this.device.transportOrder?.priority2.toString(), disabled: false }, [Validators.required]],
        transportPriority3: [{ value: this.device.transportOrder?.priority3.toString(), disabled: false }, [Validators.required]],
        wifiSSID: this.device.wifiSettings?.ssid,
        wifiPassword: this.device.wifiSettings?.password,
        wifiDHCP: this.device.wifiSettings?.dhcp,
        wifiIPAddress: this.device.wifiSettings?.srcIP,
        wifiGateway: this.device.wifiSettings?.gwAddr,
        wifiSubnetMask: this.device.wifiSettings?.subnetMask,
        ethernetDHCP: this.device.ethernetSettings?.dhcp,
        ethernetIPAddress: this.device.ethernetSettings?.srcIP,
        ethernetGateway: this.device.ethernetSettings?.gwAddr,
        ethernetSubnetMask: this.device.ethernetSettings?.subnetMask,
        hotspotEnabled: this.device.wifiHotSpotSettings?.enabled,
        hotspotPassword: [this.device.wifiHotSpotSettings?.password, Validators.compose([Validators.required, Validators.minLength(8)])],
        hotspotChannel: [this.device.wifiHotSpotSettings?.channel.toString(), Validators.required],
        hotspotIpAddress: [this.device.wifiHotSpotSettings?.ipAddress, Validators.required],
        hotspotGateway: [this.device.wifiHotSpotSettings?.gateway, Validators.required],
        hotspotSubnetMask: [this.device.wifiHotSpotSettings?.subnetMask, Validators.required],
        hotspotDHCPServer: [this.device.wifiHotSpotSettings?.dhcpServerEnabled, Validators.required],
        hotspotDHCPStart: [this.device.wifiHotSpotSettings?.dhcpIpRangeStart, Validators.required],
        hotspotDHCPEnd: [this.device.wifiHotSpotSettings?.dhcpIpRangeEnd, Validators.required],
        simMode: this.device.modemSettings ? this.getCurrentSimMode(this.device.modemSettings?.eSimNetworkMode, this.device.modemSettings?.simMode) : "",
        modemAntMode: this.device.modemSettings?.modemAntMode.toString() === "0" ? "1" : this.device.modemSettings?.modemAntMode.toString(),
        simNetworkMode: typeof this.device.modemSettings?.simNetworkMode !== "undefined" ? (this.device.modemSettings?.simNetworkMode.toString() === "0" ? "5" : this.device.modemSettings?.simNetworkMode.toString()) : null,
        eSimNetworkMode: typeof this.device.modemSettings?.eSimNetworkMode !== "undefined" ? (this.device.modemSettings?.eSimNetworkMode.toString() === "0" ? "5" : this.device.modemSettings?.eSimNetworkMode.toString()) : null,
        simVoiceDomainPreference: typeof this.device.modemSettings?.simVoiceDomainPreference !== "undefined" ? this.device.modemSettings?.simVoiceDomainPreference.toString() : null,
        eSimVoiceDomainPreference: typeof this.device.modemSettings?.eSimVoiceDomainPreference !== "undefined" ? this.device.modemSettings?.eSimVoiceDomainPreference.toString() : null,
        simAllowedOperators: this.device.modemSettings?.simAllowedOperators,
        simBlockedOperators: this.device.modemSettings?.simBlockedOperators,
        eSimAllowedOperators: this.device.modemSettings?.eSimAllowedOperators,
        eSimBlockedOperators: this.device.modemSettings?.eSimBlockedOperators,
        vpnEnabled: this.device.vpnSettings?.enabled,
        vpnServer1: this.device.vpnSettings?.server1,
        vpnServer2: this.device.vpnSettings?.server2,
        vpnDhcp: this.device.vpnSettings?.dhcp,
        vpnIPAddress: this.device.vpnSettings?.ipAddress,
        vpnGateway: this.device.vpnSettings?.gateway,
        vpnSubnetMask: this.device.vpnSettings?.subnetMask
      }),
      bluetoothSettingsFormGroup: this.fb.group({
        bluetoothEnabled: this.device.bluetoothSettings?.enabled
      })
    });

    // Remove validators if parameter is not found
    if (!this.device.hasOwnProperty('movementTimeLimit')) {
      this.deviceSettingsForm.controls.devMiscSettingsForm.get('limit').clearValidators();
      this.deviceSettingsForm.controls.devMiscSettingsForm.get('limit').updateValueAndValidity();
    }

    if (!this.device.hasOwnProperty('connectionTimeLimit')) {
      this.deviceSettingsForm.controls.devMiscSettingsForm.get('connTimeLimit').clearValidators();
      this.deviceSettingsForm.controls.devMiscSettingsForm.get('connTimeLimit').updateValueAndValidity();
    }
  }

  getCurrentSimMode(eSim: number, mode: number): string {
    if (mode === 0) {
      if (eSim != null) {
        return "4";
      }
      return "3";
    } else {
      return mode.toString();
    }
  }

  /**
   * Submit device settings form to the server
   * @returns **true** if form is valid, **false** if form is invalid
   */
  submitDeviceSettings(): boolean {
    this.saveError = false;
    this.saveSuccess = false;

    // Submit base bitpara settings if they were changed
    if (this.deviceSettingsForm.controls.devBitParaForm.dirty) {
      this.submitDeviceBitPara();
    }
    // Submit base additional settings if they were changed
    if (this.deviceSettingsForm.controls.devMiscSettingsForm.dirty) {
      this.submitDevMiscSettingsForm();
    }
    // Submit terminal parameters form if they were changed
    if (this.deviceSettingsForm.controls.terminalParameterFormGroup.dirty) {
      this.submitTerminalParameters();
    }
    // Submit TP202 additional settings if they were changed
    if (this.deviceSettingsForm.controls.tp202AdditionalForm.dirty) {
      this.submitTp202Additional();
    }
    // Submit TP202 network settings if they were changed
    if (this.deviceSettingsForm.controls.devNetworkSettingsForm.dirty) {
      this.submitNetworkSettings();
    }
    // Submit TP202G bluetooth settings if they were changed
    if (this.deviceSettingsForm.controls.bluetoothSettingsFormGroup.dirty) {
      this.submitBluetoothSettings();
    }

    return true;
  }

  submitBluetoothSettings(): void {
    let data = {
      deviceId: this.device.id,
      enabled: this.deviceSettingsForm.controls.bluetoothSettingsFormGroup.value.bluetoothEnabled
    }

    this.api.saveBluetoothSettings(data).subscribe(res => {
      this.saveSuccess = true;
    }, err => {
      this.saveError = true;
    })
  }

  submitTerminalParameters(): void {
    let data: any = {};
    data.deviceId = this.device.id;

    data.terminalParameters = {
      lockEscort: this.deviceSettingsForm.controls.terminalParameterFormGroup.value.lockEscort,
      movementMonitoring: this.deviceSettingsForm.controls.terminalParameterFormGroup.value.movementMonitoring,
      fallingDetection: this.deviceSettingsForm.controls.terminalParameterFormGroup.value.fallingDetection,
      accessControl: this.deviceSettingsForm.controls.terminalParameterFormGroup.value.accessControl,
      longPress: this.deviceSettingsForm.controls.terminalParameterFormGroup.value.longPress,
      alwaysRoaming: this.deviceSettingsForm.controls.terminalParameterFormGroup.value.alwaysRoaming
    }

    data.terminalParameters = this.deviceSettingsForm.controls.terminalParameterFormGroup.value;

    //Override other parameters to false if lock escort is enabled
    if (data.terminalParameters.lockEscort) {
      data.terminalParameters.movementMonitoring = false;
      data.terminalParameters.fallingDetection = false;
      data.terminalParameters.accessControl = false;
    }


    this.api.saveAdvancedSettings(data).subscribe(res => {
      this.saveSuccess = true;
    }, err => {
      this.saveError = true;
    })
  }

  submitDevMiscSettingsForm(): void {
    let data: any = {};
    data.deviceId = this.device.id;

    // Parse movement time limit from the form
    let splittedMovementTimeLimit = this.deviceSettingsForm.controls.devMiscSettingsForm.value.limit.split(':')
    let movementTimeLimitSeconds = (Number(splittedMovementTimeLimit[0]) * 60 * 60) + (Number(splittedMovementTimeLimit[1] * 60))
    data.movementTimeLimit = movementTimeLimitSeconds;

    // Parse movement time limit from the form
    let splittedConnLimit = this.deviceSettingsForm.controls.devMiscSettingsForm.value.connTimeLimit.split(':')
    let connTimeLimitSeconds = (Number(splittedConnLimit[0]) * 60 * 60) + (Number(splittedConnLimit[1] * 60))
    data.connectionTimeLimit = connTimeLimitSeconds;

    // Get the activity monitoring value from the form
    data.activityMonitoringEnabled = this.deviceSettingsForm.controls.devMiscSettingsForm.value.activityMonitoring;

    this.api.saveAdvancedSettings(data).subscribe(res => {
      this.saveSuccess = true;
      if (this.deviceSettingsForm.controls.devMiscSettingsForm.value.activityMonitoring != null) {
        this.device.activityMonitoring = this.deviceSettingsForm.controls.devMiscSettingsForm.value.activityMonitoring
      }
      if (data.movementTimeLimitSeconds >= 0) {
        this.device.movementTimeLimit = data.movementTimeLimitSeconds;
      }
      if (data.connTimeLimitSeconds >= 0) {
        this.device.connectionTimeLimit = data.connTimeLimitSeconds;
      }
    }, err => {
      this.saveError = true;
    })
  }

  submitDeviceBitPara(): void {
    let settings: BitParaSettings = new BitParaSettings()
    settings = this.deviceSettingsForm.controls.devBitParaForm.value;

    // Convert automatic clock brightness into ClockAls bit
    if (+ settings.clockBrightness === 4) {
      settings.clockAls = true;
      settings.clockBrightness = 2;
    } else {
      settings.clockAls = false;
    }

    let data: BitParaSettingsRequest = new BitParaSettingsRequest()
    data.deviceId = this.device.id;
    data.deviceType = this.device.deviceType;
    data.settings = settings;
    if (this.device.networkSimSettings) {
      data.networkSimSettings = {};
      data.networkSimSettings.networkMode = this.deviceSettingsForm.controls.devBitParaForm.value.networkMode;
    }

    this.api.saveDeviceBitPara(data).subscribe(res => {
      if (res) {
        this.device.bitParaSettings = res;
      }
      this.saveSuccess = true;
    }, err => {
      this.saveError = true;
    })
  }

  submitNetworkSettings(): void {
    let data = this.deviceSettingsForm.controls.devNetworkSettingsForm.value;
    data.deviceId = this.device.id;
    data.ethernetDHCP = data.ethernetDHCP ? 1 : 0;
    data.wifiDHCP = data.wifiDHCP ? 1 : 0;
    data.hotspotEnabled = data.hotspotEnabled ? 1 : 0;
    data.hotspotDHCPServer = data.hotspotDHCPServer ? 1 : 0;
    data.vpnEnabled = data.vpnEnabled ? 1 : 0
    data.vpnDhcp = data.vpnDhcp ? 1 : 0;

    //If hotspot is disabled reset all other values to empty
    if (!data.hotspotEnabled) {
      data.hotspotPassword = '';
      data.hotspotChannel = 6;
      data.hotspotIpAddress = '';
      data.hotspotGateway = '';
      data.hotspotSubnetMask = '';
      data.hotspotDHCPServer = 0;
      data.hotspotDHCPStart = '';
      data.hotspotDHCPEnd = '';
    }

    if (data.hotspotEnabled && !data.hotspotDHCPServer) {
      data.hotspotDHCPStart = '';
      data.hotspotDHCPEnd = '';
    }

    //If VPN is disabled reset all other values to empty
    if (!data.vpnEnabled) {
      data.vpnDhcp = 0;
      data.vpnGateway = '';
      data.vpnIPAddress = '';
      data.vpnSubnetMask = '';
      data.vpnServer1 = '';
      data.vpnServer2 = '';
    }

    this.api.saveDeviceNetworkSettings(data).subscribe(res => {
      this.saveSuccess = true;
    }, err => {
      this.saveError = true;
    })
  }

  submitTp202Additional(): void {
    let data = {
      deviceId: this.device.id,
      ignoreAutoAnswerNumbers: this.deviceSettingsForm.controls.tp202AdditionalForm.value.ignoreAutoAnswer,
      audioLockOpenDtmfString: this.deviceSettingsForm.controls.tp202AdditionalForm.value.dtmfCode,
      delayACPowerAlert: this.deviceSettingsForm.controls.tp202AdditionalForm.value.delayACPowerAlert,
      maxCallDuration: this.deviceSettingsForm.controls.tp202AdditionalForm.value.maxCallDuration,
      maxCallDurationExtended: this.deviceSettingsForm.controls.tp202AdditionalForm.value.maxCallDurationExtended,
      IamOk: []
    }

    //If IamOK feature is disabled set both times to 00:00
    if (!this.deviceSettingsForm.controls.tp202AdditionalForm.value.iAmOkEnabled) {
      data.IamOk[0] = "00:00";
      data.IamOk[1] = "00:00"
    } else {
      data.IamOk[0] = this.deviceSettingsForm.controls.tp202AdditionalForm.value.iAmOkStartTime;
      data.IamOk[1] = this.deviceSettingsForm.controls.tp202AdditionalForm.value.iAmOkEndTime;
    }

    this.api.saveAdvancedSettings(data).subscribe(res => {
      this.device.ignoreAutoAnswerNumbers = data.ignoreAutoAnswerNumbers;
      this.saveSuccess = true;
    }, err => {
      this.saveError = true;
    })

  }

  showMiscSettings(type: string): boolean {
    switch (type) {
      case "all":
        if (this.device.movementTimeLimit ||
          (this.device.deviceType === '04-1' ||
            this.device.deviceType === '04-2' ||
            this.device.deviceType === '05-3' ||
            this.device.deviceType === '05-4' ||
            this.device.deviceType === '05-5' ||
            this.device.deviceType === '05-7' ||
            this.device.deviceType === '05-8' ||
            this.device.deviceType === '05-9' ||
            this.device.deviceType === '06-2' ||
            this.device.deviceType.substr(0, 2) === '31' ||
            this.device.deviceType.substr(0, 2) === '34' ||
            this.device.deviceType.substr(0, 2) === '89')) {
          return true;
        }
        break;
      case "movement":
        if (this.device.hasOwnProperty('movementTimeLimit')) {
          return true;
        }
        break;
      case "connTimeLim":
        if (this.device.hasOwnProperty('connectionTimeLimit')) {
          return true;
        }
        break;
      case "activityMonitoring":
        if ((this.device.deviceType === '04-2' || this.device.deviceType === '05-4' || this.device.deviceType === '05-5' || this.device.deviceType === '05-7' || this.device.deviceType === '05-8') && this.helper.showIfEnabled("DeviceMovement")) {
          return true;
        }
        break;
      case "network":
        if (this.device.deviceType === '31-13' || this.device.deviceType === '31-14' && this.device.hasOwnProperty('transportOrder')) {
          return true;
        }
        break;
      case "tp202_additional":
        if (this.device.deviceType === '31-13' || this.device.deviceType === '31-14' && (this.device.hasOwnProperty('ignoreAutoAnswerNumbers') && this.device.hasOwnProperty('audioLockOpenDtmfString'))) {
          return true;
        }
        break;
      case "termParams":
        if ((this.device.deviceType === '05-7' || this.device.deviceType === "05-8") && this.device.hasOwnProperty('terminalParameters')) {
          return true;
        }
        break;
      case "bluetoothSettings":
        if (this.device.deviceType === '31-14') {
          return true;
        }
        break;
      case "tp202_ac_power_alert_delay":
        if (this.device.softwareVersion >= 48 && this.device.softwareVersion < 4000) {
          return true;
        }
        break;
      default:
        return false;
    }
    return false;
  }

  showNetworkSettings(type: string): boolean {
    if (this.transportPriority1.value === type || this.transportPriority2.value === type || this.transportPriority3.value === type) {
      return true;
    }
    return false;
  }

  showLockBeaconSettings(): boolean {
    if (this.device.deviceType === "32-3" || this.device.deviceType === "32-8" || this.device.deviceType === '32-1' || this.device.deviceType === '32-6') {
      return true;
    }
    return false;
  }

  // Listen transport priority select changes
  tpPrioOnChange(): void {
    this.transportPriority1.valueChanges.subscribe((value) => {
      this.tpPrioValidity(value, 1);
    })
    this.transportPriority2.valueChanges.subscribe((value) => {
      this.tpPrioValidity(value, 2);
    })
  }

  tpPrioValidity(value: string, priority: number): void {
    switch (priority) {
      case 1:
        // Prevent assigning same value multiple times by resetting values to '3' (-) in other priorities
        if (this.transportPriority2.value === value) this.transportPriority2.setValue('3');
        if (this.transportPriority3.value === value) this.transportPriority3.setValue('3');
        break;
      case 2:
        // Set priority 3 value to NONE, if selected priority 2 is same as priority 3 or NONE
        if (this.transportPriority3.value === value || value === '3') this.transportPriority3.setValue('3');
      case 3:
        break;
    }
  }

  // Controls behavior of the transport priority form if some or all device setting values are undefined
  isTpPrioUndefined(): void {
    //If priority 1 value is undefined (empty), set all priorities value 3 (-)
    if (this.transportPriority1.value === undefined) {
      this.transportPriority1.setValue('3');
      this.transportPriority2.setValue('3');
      this.transportPriority3.setValue('3');
    }
    if (this.transportPriority2.value === undefined) {
      this.transportPriority2.setValue('3');
      this.transportPriority3.setValue('3');
    }
    if (this.transportPriority3.value === undefined) {
      this.transportPriority3.setValue('3');
    }
  }

  ngOnInit(): void {
    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject.pipe(throttleTime(3000))
      .subscribe(() => {
        this.submitDeviceSettings()
      });
  }

  ngOnChanges() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.createDeviceSettingsForm();
    this.isTpPrioUndefined();
    this.tpPrioValidity(this.transportPriority1.value, 1);
    this.tpPrioValidity(this.transportPriority2.value, 2);
    this.tpPrioOnChange();
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }

  get transportPriority1(): UntypedFormControl {
    return this.deviceSettingsForm.controls.devNetworkSettingsForm.get('transportPriority1') as UntypedFormControl;
  }

  get transportPriority2(): UntypedFormControl {
    return this.deviceSettingsForm.controls.devNetworkSettingsForm.get('transportPriority2') as UntypedFormControl;
  }

  get transportPriority3(): UntypedFormControl {
    return this.deviceSettingsForm.controls.devNetworkSettingsForm.get('transportPriority3') as UntypedFormControl;
  }
}

export class BitParaSettingsRequest {
  deviceId: string;
  deviceType: string;
  settings: BitParaSettings;
  networkSimSettings: any;
}
