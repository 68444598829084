<div class="row">
    <div class="col-12">
        <h6 class="text-center routeEditGroupTitle mb-2">{{'DEVICES' | translate}}</h6>
        <p *ngIf="deviceGroups.length == 0" class="text-center mt-2">{{'NO_DEVICES' | translate}}</p>
    </div>
</div>

<div class="row">
    <ng-container *ngIf="deviceGroups.length != 0">
        <div class="col-lg-4 col-md-12 white-bg">
            <div class="card card-borders p-2">
                <h6 class="text-center devicesGroupTitle">{{'AVAILABLE_DEVICES' | translate}}</h6>

                <cdk-virtual-scroll-viewport itemSize="50">
                    <mat-list>
                        <mat-list-item *ngIf="deviceGroups | anyAvailableDevices">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    <span class="fa-light fa-check-double mr-1" style="vertical-align: middle"></span>
                                    <span class="devicesGroupTitle text-truncate">{{'ADD_ALL_DEVICES' | translate}}</span>
                                </div>

                                <mat-icon class="add-device-button" (click)="addAllDevices();">add_circle</mat-icon>
                            </div>
                        </mat-list-item>

                        <ng-container *ngFor="let deviceTypeOption of availableDeviceTypeOptions">
                            <mat-list-item *ngIf="deviceTypeOption.deviceType | anyAvailableDevicesByType : deviceGroups">
                                <div class="d-flex flex-row justify-content-between">
                                    <span class="devicesGroupTitle text-truncate">
                                        {{'ALL' | translate}} -
                                        {{ deviceTypeOption?.deviceType | deviceType }}
                                    </span>

                                    <mat-icon class="add-device-button" (click)="addDevicesByType(deviceTypeOption.deviceType)">
                                        add_circle
                                    </mat-icon>
                                </div>
                            </mat-list-item>
                        </ng-container>

                        <ng-container *cdkVirtualFor="let group of deviceGroups; trackBy: trackByLocationId">
                            <mat-list-item *ngIf="group.locationId | availableDevicesInLocation : deviceGroups">
                                <div class="d-flex flex-row justify-content-between">
                                    <div>
                                        <span *ngIf="group.locationName" class="fa-fw mr-2 fa-solid fa-house devicesGroupTitle"></span>
                                        <span class="devicesGroupTitle text-truncate">{{ group.locationName }}</span>
                                    </div>

                                    <mat-icon class="add-device-button" (click)="addDevicesByLocationId(group.locationId)">
                                        add_circle
                                    </mat-icon>
                                </div>
                            </mat-list-item>

                            <ng-container *ngFor="let device of group.deviceOptions; trackBy: trackByDeviceFn">
                                <mat-list-item *ngIf="!device.selected">
                                    <div class="d-flex flex-row justify-content-between">

                                        <div class="text-truncate">
                                            <span [ngClass]="device?.deviceType | deviceIconClass" class="mr-1"
                                                style="vertical-align: middle">
                                            </span>

                                            <ng-container *ngIf="device.deviceName">{{ device.deviceName }}</ng-container>
                                            <ng-container *ngIf="!device.deviceName">{{ device.deviceId }}</ng-container>
                                            ({{ device.deviceType | deviceType }})
                                        </div>

                                        <mat-icon class="add-device-button" (click)="addDevice(device); saveDevicesToTempRoute();">
                                            add_circle
                                        </mat-icon>
                                    </div>
                                </mat-list-item>

                                <ng-container *ngFor="let pairedDevice of device.pairedDeviceOptions; trackBy: trackByDeviceFn">
                                    <mat-list-item *ngIf="!pairedDevice.selected">
                                        <div class="d-flex flex-row justify-content-between">
                                            <div class="text-truncate">
                                                <span [ngClass]="pairedDevice?.deviceType | deviceIconClass" class="mr-1"
                                                    style="vertical-align: middle">
                                                </span>
                                                <ng-container *ngIf="pairedDevice.deviceName">{{ pairedDevice.deviceName }}</ng-container>
                                                <ng-container *ngIf="!pairedDevice.deviceName">{{ pairedDevice.deviceId }}</ng-container>
                                                ({{ pairedDevice?.deviceType | deviceType }})
                                            </div>

                                            <mat-icon class="add-device-button" (click)="addDevice(pairedDevice); this.saveDevicesToTempRoute();">
                                                add_circle
                                            </mat-icon>
                                        </div>
                                    </mat-list-item>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </mat-list>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>

        <!--Selected devices-->
        <div class="col-lg-4 col-md-12 white-bg">
            <div class="card card-borders p-2">
                <h6 class="text-center devicesGroupTitle">{{'SELECTED_DEVICES' | translate}}</h6>

                <cdk-virtual-scroll-viewport itemSize="50">
                    <mat-list>
                        <mat-list-item *ngIf="deviceGroups | anySelectedDevices">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    <span class="fa-light fa-check-double mr-1" style="vertical-align: middle"></span>
                                    <span class="devicesGroupTitle text-truncate">
                                        {{ 'REMOVE_ALL_DEVICES' | translate }}
                                    </span>
                                </div>
                                <mat-icon class="remove-device-button" (click)="removeAllDevices();">remove_circle</mat-icon>
                            </div>
                        </mat-list-item>

                        <div *ngFor="let deviceTypeOption of availableDeviceTypeOptions" [ngClass]="{'highlight-selection' : deviceTypeOption.highlight}">
                            <mat-list-item *ngIf="deviceTypeOption?.deviceType | deviceTypeFoundInGroups : deviceGroups">
                                <div class="d-flex flex-row justify-content-between align-items-center">
                                    <span
                                      class="text-truncate devicesGroupTitle selection-container"
                                      (click)="
                                          highlightDeviceTypeOption(deviceTypeOption);
                                          selectedDevicesForActivations = getDevicesByType(deviceTypeOption.deviceType);
                                          deviceTypeGroupHighlighted = true;"
                                    >
                                        {{'ALL' | translate}} - {{ deviceTypeOption?.deviceType | deviceType }}
                                    </span>

                                    <mat-icon class="remove-device-button"
                                        (click)="removeDevicesByType(deviceTypeOption.deviceType)">
                                        remove_circle
                                    </mat-icon>
                                </div>
                            </mat-list-item>
                        </div>

                        <ng-container *cdkVirtualFor="let group of deviceGroups; trackBy: trackByLocationId">
                            <mat-list-item *ngIf="group.locationId | foundInLocation : deviceGroups">
                                <div class="d-flex flex-row justify-content-between align-items-center">
                                    <div>
                                        <span *ngIf="group.locationName"
                                            class="fa-fw mr-2 fa-solid fa-house devicesGroupTitle"></span>

                                        <span class="devicesGroupTitle text-truncate">
                                            {{ group.locationName }}
                                        </span>
                                    </div>

                                    <mat-icon class="remove-device-button" (click)="removeDevicesByLocationId(group.locationId)">
                                        remove_circle
                                    </mat-icon>
                                </div>
                            </mat-list-item>

                            <ng-container *ngFor="let device of group.deviceOptions; trackBy: trackByDeviceFn">
                                <div [@addDeviceAnimation]="device.selected" [ngClass]="{'highlight-selection' : device.highlight}">
                                    <mat-list-item *ngIf="device.selected">
                                        <div class="d-flex flex-row justify-content-between align-items-center">

                                            <div
                                              class="text-truncate selection-container d-flex align-items-center"
                                              (click)="highlightDeviceOption(device);
                                              deviceTypeGroupHighlighted = false;
                                              selectedDevicesForActivations = [device]"
                                            >
                                                <span
                                                    [ngClass]="device?.deviceType | deviceIconClass"
                                                    class="mr-1" style="vertical-align: middle">
                                                </span>

                                                <div [ngClass]="{'text-danger' : device?.activations.length === 0}"
                                                    class="d-inline">
                                                    <ng-container *ngIf="device.deviceName">{{device.deviceName}}</ng-container>
                                                    <ng-container *ngIf="!device.deviceName">{{device.deviceId}}</ng-container>
                                                    <span> ({{ device.deviceType | deviceType }})</span>
                                                </div>
                                            </div>

                                            <mat-icon class="remove-device-button" (click)="removeDevice(device)">
                                                remove_circle
                                            </mat-icon>
                                        </div>
                                    </mat-list-item>
                                </div>

                                <div
                                  *ngFor="let pairedDevice of device.pairedDeviceOptions; trackBy: trackByDeviceFn"
                                  [@addedAnimation]="device.selected"
                                  [ngClass]="{'highlight-selection' : pairedDevice.highlight}"
                                  class="mb-1"
                                >
                                    <mat-list-item *ngIf="pairedDevice.selected">
                                        <div class="d-flex flex-row justify-content-between align-items-center">

                                            <div class="text-truncate selection-container d-flex align-items-center"
                                                (click)="highlightDeviceOption(pairedDevice);
                                                     deviceTypeGroupHighlighted = false;
                                                     selectedDevicesForActivations = [pairedDevice]">

                                                <span [ngClass]="pairedDevice?.deviceType | deviceIconClass" class="mr-1"
                                                    style="vertical-align: middle"></span>

                                                <div [ngClass]="{'text-danger' : pairedDevice?.activations.length === 0}"
                                                    class="d-inline">
                                                    <ng-container *ngIf="pairedDevice.deviceName">{{ pairedDevice.deviceName }}</ng-container>
                                                    <ng-container *ngIf="!pairedDevice.deviceName">{{ pairedDevice.deviceId }}</ng-container>
                                                    <span> ({{ pairedDevice.deviceType | deviceType }})</span>
                                                </div>
                                            </div>

                                            <mat-icon class="remove-device-button" (click)="removeDevice(pairedDevice)">
                                                remove_circle
                                            </mat-icon>
                                        </div>
                                    </mat-list-item>
                                </div>
                            </ng-container>
                        </ng-container>
                    </mat-list>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>

        <div class="col-lg-4 col-md-12 white-bg">
            <div class="card card-borders p-2 activations-list">
                <h6 class="text-center devicesGroupTitle">{{'ALARM_TYPE' | translate}}</h6>
                <p *ngIf="!selectedDevicesForActivations[0]?.deviceId | foundInGroups : deviceGroups" class="text-center">
                    {{ 'NO_DEVICES_SELECTED' | translate }}
                </p>
                <p *ngIf="!deviceTypeGroupHighlighted && selectedDevicesForActivations[0]?.deviceId | foundInGroups : deviceGroups" class="text-center">
                    {{ selectedDevicesForActivations[0]?.deviceName }}
                </p>
                <p *ngIf="deviceTypeGroupHighlighted && selectedDevicesForActivations[0]?.deviceId | foundInGroups : deviceGroups" class="text-center">
                    {{ selectedDevicesForActivations[0]?.deviceType | deviceType }}
                </p>

                <ng-container *ngIf="selectedDevicesForActivations[0]?.deviceId | foundInGroups : deviceGroups">
                    <ng-container
                      *ngFor="let selectedDeviceActivation of (selectedDevicesForActivations | mapDeviceActivation : allActivations : deviceTypeAvailableActivations) | keyvalue"
                    >
                        <mat-checkbox class="activation-checkbox"
                            #checkbox
                            [checked]="selectedDeviceActivation.value === 1"
                            [indeterminate]="selectedDeviceActivation.value === 2"
                            (change)="!checkbox.checked
                                ? removeActivationFromDevices(selectedDeviceActivation.key.bindDeviceIds, selectedDeviceActivation.key)
                                : addActivationToDevices(selectedDeviceActivation.key.bindDeviceIds, selectedDeviceActivation.key)">

                            <!-- Replace activation name if the name contains '%CTL%' -->
                            <ng-container *ngIf="selectedDeviceActivation.key.name.includes('%CTL%'); else name">
                                {{'NO_CONNECTION_WITHIN_TIME' | translate}}
                            </ng-container>
                            <ng-template #name> {{selectedDeviceActivation.key.name}}</ng-template>
                        </mat-checkbox>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
